<template>
  <div v-html="legacySystemHTML"></div>
</template>

<script>
import toSvg from "./../toSvg";
export default {
  data() {
    return {
      legacySystemHTML: `
      <?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="77.222649mm"
   height="80.190201mm"
   viewBox="0 0 77.222649 99.190201"
   version="1.1"
   id="svg5"
   xml:space="preserve"
   inkscape:version="1.2 (dc2aedaf03, 2022-05-15)"
   sodipodi:docname="MG__5cfrs_3dec_med+telecontrol.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
     id="namedview7"
     pagecolor="#ffffff"
     bordercolor="#000000"
     borderopacity="0.25"
     inkscape:showpageshadow="2"
     inkscape:pageopacity="0.0"
     inkscape:pagecheckerboard="0"
     inkscape:deskcolor="#d1d1d1"
     inkscape:document-units="mm"
     showgrid="true"
     inkscape:zoom="2"
     inkscape:cx="72.5"
     inkscape:cy="137"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="1912"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="layer1"><inkscape:grid
       type="xygrid"
       id="grid107586" /></sodipodi:namedview><defs
     id="defs2"><linearGradient
   id="linearGradient10077"
   inkscape:swatch="solid"><stop
     style="stop-color:#ffffff;stop-opacity:0.99215686;"
     offset="0"
     id="stop10075" /></linearGradient><filter
   inkscape:collect="always"
   style="color-interpolation-filters:sRGB"
   id="filter4555-7"
   x="-1.0552142e-05"
   width="1.0000211"
   y="-0.00010639225"
   height="1.0002128"><feGaussianBlur
     inkscape:collect="always"
     stdDeviation="0.0011536701"
     id="feGaussianBlur4557-9" /></filter><style
   type="text/css"
   id="style2852">
   <![CDATA[
    .str0 {stroke:#1F1A17;stroke-width:0.374252}
    .str2 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round}
    .str1 {stroke:#1F1A17;stroke-width:0.374252;stroke-dasharray:1.871260 1.122756}
    .str4 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.122756}
    .str3 {stroke:#1F1A17;stroke-width:0.374252;stroke-linecap:round;stroke-dasharray:1.871260 1.871260}
    .fil0 {fill:none}
    .fil1 {fill:#1F1A17;fill-rule:nonzero}
   ]]>
  </style><style
   id="style23281">.cls-1{fill:none;}</style><linearGradient
   id="SVGID_1_"
   gradientUnits="userSpaceOnUse"
   x1="20.3297"
   y1="40.395"
   x2="105.3797"
   y2="40.395"
   gradientTransform="matrix(0.11001501,0,0,0.10294026,97.735714,90.438158)">
				<stop
   offset="0"
   style="stop-color:#BDAD9B"
   id="stop6938" />
				<stop
   offset="0.3497"
   style="stop-color:#BFB09E"
   id="stop6940" />
				<stop
   offset="0.6122"
   style="stop-color:#C6B8A8"
   id="stop6942" />
				<stop
   offset="0.8454"
   style="stop-color:#D2C6B8"
   id="stop6944" />
				<stop
   offset="1"
   style="stop-color:#DDD3C7"
   id="stop6946" />
			</linearGradient><linearGradient
   id="SVGID_2_"
   gradientUnits="userSpaceOnUse"
   x1="353.29971"
   y1="40.395"
   x2="438.3497"
   y2="40.395"
   gradientTransform="matrix(0.11001501,0,0,0.10294026,97.735714,90.438158)">
				<stop
   offset="0"
   style="stop-color:#BDAD9B"
   id="stop6951" />
				<stop
   offset="0.3497"
   style="stop-color:#BFB09E"
   id="stop6953" />
				<stop
   offset="0.6122"
   style="stop-color:#C6B8A8"
   id="stop6955" />
				<stop
   offset="0.8454"
   style="stop-color:#D2C6B8"
   id="stop6957" />
				<stop
   offset="1"
   style="stop-color:#DDD3C7"
   id="stop6959" />
			</linearGradient><radialGradient
   id="SVGID_3_"
   cx="229.7347"
   cy="164.94"
   r="248.4614"
   gradientTransform="matrix(0.11001501,0,0,0.07605227,97.735714,94.873268)"
   gradientUnits="userSpaceOnUse">
				<stop
   offset="0"
   style="stop-color:#B19C88"
   id="stop6998" />
				<stop
   offset="0.6279"
   style="stop-color:#B29E8A"
   id="stop7000" />
				<stop
   offset="0.8538"
   style="stop-color:#B6A491"
   id="stop7002" />
				<stop
   offset="1"
   style="stop-color:#BDAD9B"
   id="stop7004" />
			</radialGradient><radialGradient
   id="SVGID_4_"
   cx="229.7347"
   cy="199.38541"
   r="224.6075"
   gradientTransform="matrix(0.10999301,-0.00205881,0.00137519,0.07832724,97.466199,93.257425)"
   gradientUnits="userSpaceOnUse">
				<stop
   offset="0"
   style="stop-color:#DDD3C7"
   id="stop7009" />
				<stop
   offset="0.3039"
   style="stop-color:#DBD0C4"
   id="stop7011" />
				<stop
   offset="0.5319"
   style="stop-color:#D4C8BA"
   id="stop7013" />
				<stop
   offset="0.7352"
   style="stop-color:#C9BAAA"
   id="stop7015" />
				<stop
   offset="0.9231"
   style="stop-color:#B9A694"
   id="stop7017" />
				<stop
   offset="1"
   style="stop-color:#B19C88"
   id="stop7019" />
			</radialGradient><linearGradient
   id="SVGID_5_"
   gradientUnits="userSpaceOnUse"
   x1="74.8797"
   y1="492.39999"
   x2="102.9797"
   y2="492.39999"
   gradientTransform="matrix(0.11001501,0,0,0.10294026,97.735714,90.438158)">
				<stop
   offset="0"
   style="stop-color:#DDD4C9"
   id="stop7024" />
				<stop
   offset="0.3566"
   style="stop-color:#DBD1C6"
   id="stop7026" />
				<stop
   offset="0.6387"
   style="stop-color:#D4C9BC"
   id="stop7028" />
				<stop
   offset="0.8945"
   style="stop-color:#C9BBAB"
   id="stop7030" />
				<stop
   offset="1"
   style="stop-color:#C3B3A2"
   id="stop7032" />
			</linearGradient><linearGradient
   id="SVGID_6_"
   gradientUnits="userSpaceOnUse"
   x1="347.7197"
   y1="492.39999"
   x2="375.8197"
   y2="492.39999"
   gradientTransform="matrix(0.11001501,0,0,0.10294026,97.735714,90.438158)">
				<stop
   offset="0"
   style="stop-color:#DDD4C9"
   id="stop7037" />
				<stop
   offset="0.3566"
   style="stop-color:#DBD1C6"
   id="stop7039" />
				<stop
   offset="0.6387"
   style="stop-color:#D4C9BC"
   id="stop7041" />
				<stop
   offset="0.8945"
   style="stop-color:#C9BBAB"
   id="stop7043" />
				<stop
   offset="1"
   style="stop-color:#C3B3A2"
   id="stop7045" />
			</linearGradient><radialGradient
   id="SVGID_7_"
   cx="231.3898"
   cy="383.52481"
   r="214.43671"
   gradientTransform="matrix(0.11001501,0,0,0.06573765,97.735714,104.70804)"
   gradientUnits="userSpaceOnUse">
				<stop
   offset="0"
   style="stop-color:#DDD4C9"
   id="stop7052" />
				<stop
   offset="0.3566"
   style="stop-color:#DBD1C6"
   id="stop7054" />
				<stop
   offset="0.6387"
   style="stop-color:#D4C9BC"
   id="stop7056" />
				<stop
   offset="0.8945"
   style="stop-color:#C9BBAB"
   id="stop7058" />
				<stop
   offset="1"
   style="stop-color:#C3B3A2"
   id="stop7060" />
			</radialGradient><linearGradient
   id="SVGID_8_"
   gradientUnits="userSpaceOnUse"
   x1="299.2645"
   y1="294.6983"
   x2="299.2645"
   y2="481.42361">
				<stop
   offset="0"
   style="stop-color:#C3B3A2"
   id="stop7065" />
				<stop
   offset="0.1055"
   style="stop-color:#C9BBAB"
   id="stop7067" />
				<stop
   offset="0.3613"
   style="stop-color:#D4C9BC"
   id="stop7069" />
				<stop
   offset="0.6434"
   style="stop-color:#DBD1C6"
   id="stop7071" />
				<stop
   offset="1"
   style="stop-color:#DDD4C9"
   id="stop7073" />
			</linearGradient><linearGradient
   id="SVGID_9_"
   gradientUnits="userSpaceOnUse"
   x1="147.7328"
   y1="294.6983"
   x2="147.7328"
   y2="481.42361">
				<stop
   offset="0"
   style="stop-color:#C3B3A2"
   id="stop7078" />
				<stop
   offset="0.1055"
   style="stop-color:#C9BBAB"
   id="stop7080" />
				<stop
   offset="0.3613"
   style="stop-color:#D4C9BC"
   id="stop7082" />
				<stop
   offset="0.6434"
   style="stop-color:#DBD1C6"
   id="stop7084" />
				<stop
   offset="1"
   style="stop-color:#DDD4C9"
   id="stop7086" />
			</linearGradient>
			<linearGradient
   id="linearGradient30358"
   gradientUnits="userSpaceOnUse"
   x1="20.3297"
   y1="40.395"
   x2="105.3797"
   y2="40.395">
				<stop
   offset="0"
   style="stop-color:#BDAD9B"
   id="stop30348" />
				<stop
   offset="0.3497"
   style="stop-color:#BFB09E"
   id="stop30350" />
				<stop
   offset="0.6122"
   style="stop-color:#C6B8A8"
   id="stop30352" />
				<stop
   offset="0.8454"
   style="stop-color:#D2C6B8"
   id="stop30354" />
				<stop
   offset="1"
   style="stop-color:#DDD3C7"
   id="stop30356" />
			</linearGradient>
			
			<linearGradient
   id="linearGradient30371"
   gradientUnits="userSpaceOnUse"
   x1="353.29971"
   y1="40.395"
   x2="438.3497"
   y2="40.395">
				<stop
   offset="0"
   style="stop-color:#BDAD9B"
   id="stop30361" />
				<stop
   offset="0.3497"
   style="stop-color:#BFB09E"
   id="stop30363" />
				<stop
   offset="0.6122"
   style="stop-color:#C6B8A8"
   id="stop30365" />
				<stop
   offset="0.8454"
   style="stop-color:#D2C6B8"
   id="stop30367" />
				<stop
   offset="1"
   style="stop-color:#DDD3C7"
   id="stop30369" />
			</linearGradient>
			
			
			
			
			
				<radialGradient
   id="radialGradient30399"
   cx="229.7347"
   cy="164.94"
   r="248.4614"
   gradientTransform="matrix(1,0,0,0.7388,0,43.0843)"
   gradientUnits="userSpaceOnUse">
				<stop
   offset="0"
   style="stop-color:#B19C88"
   id="stop30391" />
				<stop
   offset="0.6279"
   style="stop-color:#B29E8A"
   id="stop30393" />
				<stop
   offset="0.8538"
   style="stop-color:#B6A491"
   id="stop30395" />
				<stop
   offset="1"
   style="stop-color:#BDAD9B"
   id="stop30397" />
			</radialGradient>
			
			
				<radialGradient
   id="radialGradient30414"
   cx="229.7347"
   cy="199.38541"
   r="224.6075"
   gradientTransform="matrix(0.9998,-0.02,0.0125,0.7609,-2.4498,27.3874)"
   gradientUnits="userSpaceOnUse">
				<stop
   offset="0"
   style="stop-color:#DDD3C7"
   id="stop30402" />
				<stop
   offset="0.3039"
   style="stop-color:#DBD0C4"
   id="stop30404" />
				<stop
   offset="0.5319"
   style="stop-color:#D4C8BA"
   id="stop30406" />
				<stop
   offset="0.7352"
   style="stop-color:#C9BAAA"
   id="stop30408" />
				<stop
   offset="0.9231"
   style="stop-color:#B9A694"
   id="stop30410" />
				<stop
   offset="1"
   style="stop-color:#B19C88"
   id="stop30412" />
			</radialGradient>
			
			<linearGradient
   id="linearGradient30427"
   gradientUnits="userSpaceOnUse"
   x1="74.8797"
   y1="492.39999"
   x2="102.9797"
   y2="492.39999">
				<stop
   offset="0"
   style="stop-color:#DDD4C9"
   id="stop30417" />
				<stop
   offset="0.3566"
   style="stop-color:#DBD1C6"
   id="stop30419" />
				<stop
   offset="0.6387"
   style="stop-color:#D4C9BC"
   id="stop30421" />
				<stop
   offset="0.8945"
   style="stop-color:#C9BBAB"
   id="stop30423" />
				<stop
   offset="1"
   style="stop-color:#C3B3A2"
   id="stop30425" />
			</linearGradient>
			
			<linearGradient
   id="linearGradient30440"
   gradientUnits="userSpaceOnUse"
   x1="347.7197"
   y1="492.39999"
   x2="375.8197"
   y2="492.39999">
				<stop
   offset="0"
   style="stop-color:#DDD4C9"
   id="stop30430" />
				<stop
   offset="0.3566"
   style="stop-color:#DBD1C6"
   id="stop30432" />
				<stop
   offset="0.6387"
   style="stop-color:#D4C9BC"
   id="stop30434" />
				<stop
   offset="0.8945"
   style="stop-color:#C9BBAB"
   id="stop30436" />
				<stop
   offset="1"
   style="stop-color:#C3B3A2"
   id="stop30438" />
			</linearGradient>
			
			
			
				<radialGradient
   id="radialGradient30454"
   cx="231.3898"
   cy="383.52481"
   r="214.43671"
   gradientTransform="matrix(1,0,0,0.6386,0,138.623)"
   gradientUnits="userSpaceOnUse">
				<stop
   offset="0"
   style="stop-color:#DDD4C9"
   id="stop30444" />
				<stop
   offset="0.3566"
   style="stop-color:#DBD1C6"
   id="stop30446" />
				<stop
   offset="0.6387"
   style="stop-color:#D4C9BC"
   id="stop30448" />
				<stop
   offset="0.8945"
   style="stop-color:#C9BBAB"
   id="stop30450" />
				<stop
   offset="1"
   style="stop-color:#C3B3A2"
   id="stop30452" />
			</radialGradient>
			
			<linearGradient
   id="linearGradient30467"
   gradientUnits="userSpaceOnUse"
   x1="299.2645"
   y1="294.6983"
   x2="299.2645"
   y2="481.42361">
				<stop
   offset="0"
   style="stop-color:#C3B3A2"
   id="stop30457" />
				<stop
   offset="0.1055"
   style="stop-color:#C9BBAB"
   id="stop30459" />
				<stop
   offset="0.3613"
   style="stop-color:#D4C9BC"
   id="stop30461" />
				<stop
   offset="0.6434"
   style="stop-color:#DBD1C6"
   id="stop30463" />
				<stop
   offset="1"
   style="stop-color:#DDD4C9"
   id="stop30465" />
			</linearGradient>
			
			<linearGradient
   id="linearGradient30480"
   gradientUnits="userSpaceOnUse"
   x1="147.7328"
   y1="294.6983"
   x2="147.7328"
   y2="481.42361">
				<stop
   offset="0"
   style="stop-color:#C3B3A2"
   id="stop30470" />
				<stop
   offset="0.1055"
   style="stop-color:#C9BBAB"
   id="stop30472" />
				<stop
   offset="0.3613"
   style="stop-color:#D4C9BC"
   id="stop30474" />
				<stop
   offset="0.6434"
   style="stop-color:#DBD1C6"
   id="stop30476" />
				<stop
   offset="1"
   style="stop-color:#DDD4C9"
   id="stop30478" />
			</linearGradient>
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
			
		</defs><g
     inkscape:label="Capa 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-85.227387,-90.921029)"><g
       id="Capa_10_copia"
       transform="matrix(0.26458333,0,0,0.26458333,-8.7084723,51.930251)">
	</g><path
       id="rect3318-5"
       style="fill:#ebeeef;fill-opacity:1;stroke-width:0.44144"
       d="m 85.814304,159.47418 h 76.634916 v 30.63705 H 85.814304 Z" /><path
       id="rect3320-1"
       style="fill:#3c1d69;fill-opacity:1;stroke-width:1.00014;filter:url(#filter4555-7)"
       d="M 3.9756525,175.42555 H 266.36866 v 26.02453 H 3.9756525 Z"
       transform="matrix(0.29206216,0,0,0.25016633,84.653018,115.5886)" /><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="112.64575"
       y="165.94705"
       id="text294-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-7"
         x="112.64575"
         y="165.94705"
         style="font-size:3.46461px;fill:#ffffff;stroke-width:0.28872">Información</tspan></text><text
       xml:space="preserve"
       style="font-size:3.175px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.299447"
       x="88.905998"
       y="177.71822"
       id="text294-2-5"
       transform="scale(1.0491626,0.95314111)"><tspan
         sodipodi:role="line"
         id="tspan292-1-99"
         x="88.905998"
         y="177.71822"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.299447">ID Disp. :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="97.747749"
       y="176.02061"
       id="text294-2-2-4-3"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-1-9-9"
         x="85.747749"
         y="176.02061"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"><tspan
   style="font-size:4.07968px"
   id="tspan54468">Alias</tspan> :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="90.514687"
       y="180.34782"
       id="text294-2-0-10"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="tspan292-1-9-2"
         x="90.514687"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"><tspan
   style="font-size:3.175px"
   id="tspan54472">Ubicación</tspan> :</tspan></text><text
       xml:space="preserve"
       style="font-size:3.175px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.286601"
       x="84.874893"
       y="183.35675"
       id="text294-2-8-2"
       transform="scale(1.0041531,0.99586407)"><tspan
         sodipodi:role="line"
         x="84.874893"
         y="183.35675"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.286601"
         id="tspan380-3">  Ult. medición :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.07968px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78088"
       y="171.38197"
       id="text294-2-02-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-device"
         x="111.78088"
         y="171.38197"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">00000000</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="92.910698"
       y="189.25705"
       id="text294-2-8-9-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="92.910698"
         y="189.25705"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="tspan380-4-4">  <tspan
   style="font-size:4.07968px"
   id="tspan54476">Pulsos</tspan>  :</tspan></text><text
       xml:space="preserve"
       style="font-size:4.07968px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="112.16678"
       y="175.80244"
       id="text294-2-2-6-2"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-Alias"
         x="99.16678"
         y="175.80244"
         style="fill:#232626;fill-opacity:1;stroke-width:0.28872;font-size:4.07968px">-</tspan></text><text
       xml:space="preserve"
       style="font-size:3.175px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.78037"
       y="180.34782"
       id="text294-2-0-1-5"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         id="txt-ubicacion"
         x="111.78037"
         y="180.34782"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872">0,0</tspan></text><text
       xml:space="preserve"
       style="font-size:3.175px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.7187"
       y="184.71269"
       id="text294-2-8-0-0"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.7187"
         y="184.71269"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-UltimaAct">00/00/00   00:00:00</tspan></text><text
       xml:space="preserve"
       style="font-size:4.61949px;line-height:0.85;font-family:sans-serif;fill:#ffffff;stroke-width:0.28872"
       x="110.80153"
       y="189.07758"
       id="text294-2-8-9-7-3"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="110.80153"
         y="189.07758"
         style="font-size:4.07968px;fill:#1a1a1a;stroke-width:0.28872"
         id="tspan380-4-8-5">  </tspan></text><text
       xml:space="preserve"
       style="font-size:3.175px;line-height:0.85;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.28872"
       x="111.91395"
       y="189.43652"
       id="text294-2-8-9-2-9"
       transform="scale(1.0115789,0.98855363)"><tspan
         sodipodi:role="line"
         x="111.91395"
         y="189.43652"
         style="font-size:4.07968px;fill:#232626;fill-opacity:1;stroke-width:0.28872"
         id="txt-Pulsos">0</tspan></text><g
       id="Capa_x0020_1"
       inkscape:label="Capa 1"
       inkscape:groupmode="layer"
       transform="matrix(0.23517337,0,0,0.25639214,157.04571,70.316583)"
       style="clip-rule:evenodd;fill-rule:evenodd;image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision">
  <metadata
   id="CorelCorpID_0Corel-Layer" />
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
 <g
   data-name="Layer 2"
   id="Layer_2"
   transform="matrix(1.1250565,0,0,1.0192143,-35.784344,98.528906)"
   style="display:inline;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><g
     id="S-Fraude"
     style="display:none;fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none"><path
       d="M 22.7,28 H 9.3 a 6.25,6.25 0 0 1 -5.47,-3.15 6.15,6.15 0 0 1 0,-6.22 L 10.56,7.12 a 6.3,6.3 0 0 1 10.88,0 l 6.71,11.51 v 0 a 6.15,6.15 0 0 1 0,6.22 A 6.25,6.25 0 0 1 22.7,28 Z M 16,6 A 4.24,4.24 0 0 0 12.29,8.12 L 5.58,19.64 a 4.15,4.15 0 0 0 0,4.21 A 4.23,4.23 0 0 0 9.3,26 h 13.4 a 4.23,4.23 0 0 0 3.73,-2.15 4.15,4.15 0 0 0 0,-4.21 L 19.71,8.12 A 4.24,4.24 0 0 0 16,6 Z"
       id="path23287"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       class="cls-1"
       d="m 16,12 a 0.54,0.54 0 0 0 -0.44,0.22 0.52,0.52 0 0 0 -0.1,0.48 L 16,14.88 16.54,12.7 A 0.52,0.52 0 0 0 16.44,12.22 0.54,0.54 0 0 0 16,12 Z"
       id="path23289"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><path
       d="m 18,11 a 2.56,2.56 0 0 0 -4,0 2.5,2.5 0 0 0 -0.46,2.19 L 15,19.24 a 1,1 0 0 0 1.94,0 l 1.51,-6.06 A 2.5,2.5 0 0 0 18,11 Z M 16.54,12.7 16,14.88 15.46,12.7 a 0.52,0.52 0 0 1 0.1,-0.48 0.55,0.55 0 0 1 0.88,0 0.52,0.52 0 0 1 0.1,0.48 z"
       id="path23291"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /><circle
       cx="16"
       cy="22.5"
       r="1.5"
       id="circle23293"
       style="fill:#c83737;fill-opacity:0.9;stroke-width:1.00021;stroke-dasharray:none" /></g></g><g
   id="frame"
   transform="matrix(0.91053163,0,0,0.83850055,-90.100031,336.92569)"><rect
     class="cls-1"
     height="32"
     width="32"
     id="rect23296"
     x="0"
     y="0" /></g><polygon
   style="display:inline;fill:#e6e6e6;fill-opacity:1"
   points="32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 "
   id="checkConnect-5"
   transform="matrix(1.0071785,0,0,1.0856859,-26.521454,426.97583)" /></g><path
       d="m 151.74312,146.45934 c -0.0815,0.0339 -0.14891,0.1433 -0.14891,0.24537 v 0.0898 l -0.80834,0.009 c -0.77571,0.009 -0.81532,0.0139 -0.94109,0.0631 -0.24464,0.10209 -0.41233,0.30116 -0.51016,0.60238 -0.0441,0.14096 -0.0466,0.22105 -0.0466,4.15132 0,3.93029 9.3e-4,4.0104 0.0466,4.15134 0.0977,0.30116 0.26552,0.50035 0.51016,0.60238 l 0.13043,0.0558 h 2.56239 2.5624 l 0.13281,-0.0558 c 0.1864,-0.0777 0.3494,-0.2405 0.44256,-0.43726 l 0.0768,-0.16273 0.007,-4.10272 c 0.005,-4.53026 0.0153,-4.23148 -0.13993,-4.4744 -0.0417,-0.0654 -0.12805,-0.15786 -0.19335,-0.20888 -0.21433,-0.16764 -0.23991,-0.1725 -1.06222,-0.1846 l -0.73847,-0.009 -0.0119,-0.10935 c -0.007,-0.0753 -0.03,-0.1311 -0.0724,-0.17492 l -0.0603,-0.0632 -0.84558,-0.006 c -0.46357,-8.9e-4 -0.86657,0.006 -0.89221,0.017 z m 3.35209,0.81132 c 0.0908,0.0387 0.21897,0.18462 0.24227,0.27693 0.0236,0.0851 0.0236,8.06215 0,8.14716 -0.0234,0.0922 -0.15128,0.23805 -0.24227,0.27692 -0.11195,0.0511 -5.00365,0.0511 -5.11551,0 -0.10023,-0.041 -0.21662,-0.18457 -0.24693,-0.2988 -0.0346,-0.12864 -0.0346,-7.9747 0,-8.10341 0.028,-0.1115 0.14688,-0.25505 0.2423,-0.2988 0.10448,-0.0484 5.01065,-0.0484 5.12014,0 z"
       id="Battery_Level"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1.73255;stroke-miterlimit:4;stroke-dasharray:none" /><path
       d="m 150.30153,148.49327 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50696 h -2.20826 -2.20831 z"
       id="bat_L5"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524;paint-order:stroke fill markers" /><path
       d="m 150.30153,150.06911 v 0.50696 h 2.20831 2.20826 v -0.50696 -0.50695 h -2.20826 -2.20831 z"
       id="bat_L4"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.0071252" /><path
       d="m 150.30153,151.57646 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L3"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712529" /><path
       d="m 150.30153,153.15235 v 0.50693 h 2.20831 2.20826 v -0.50693 -0.50694 h -2.20826 -2.20831 z"
       id="bat_L2"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712524" /><path
       d="m 150.30153,154.72822 v 0.50694 h 2.20831 2.20826 v -0.50694 -0.50693 h -2.20826 -2.20831 z"
       id="bat_L1"
       style="display:inline;opacity:0.906452;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.00712532" /><g
       id="g76451"
       style="display:inline;fill:#1a1a1a;fill-opacity:1;stroke-width:12.3402;stroke-dasharray:none"
       transform="matrix(0.30487124,0,0,0.321627,80.093256,258.91867)"><g
         id="sFraude"
         style="fill:#1a1a1a"><g
           id="g27831"
           style="fill:#1a1a1a"><g
             id="g31956"
             transform="matrix(0.07298313,0,0,0.06903844,38.558924,-350.55249)"
             style="fill:#1a1a1a"><g
               id="g31865"
               style="fill:#1a1a1a">
	<g
   id="g31863"
   style="fill:#1a1a1a">
		<path
   d="M 394.095,330.155 C 374.315,290.586 373.76,249.848 373.76,249.447 v -46.08 c 0,-64.93 -52.83,-117.76 -117.76,-117.76 -64.93,0 -117.76,52.83 -117.76,117.76 v 46.08 c 0,0.333 -0.478,40.986 -20.335,80.708 -3.968,7.936 -3.541,17.357 1.118,24.909 4.668,7.552 12.902,12.143 21.777,12.143 h 65.809 c -1.109,4.087 -1.809,8.354 -1.809,12.8 0,28.237 22.963,51.2 51.2,51.2 28.237,0 51.2,-22.963 51.2,-51.2 0,-4.446 -0.7,-8.713 -1.809,-12.8 H 371.2 c 8.875,0 17.109,-4.591 21.777,-12.143 4.668,-7.544 5.086,-16.973 1.118,-24.909 z M 256,405.606 c -14.114,0 -25.6,-11.486 -25.6,-25.6 0,-4.762 1.664,-8.977 3.934,-12.8 h 43.332 c 2.27,3.823 3.934,8.038 3.934,12.8 0,14.115 -11.486,25.6 -25.6,25.6 z m -115.2,-64 c 23.04,-46.08 23.04,-92.16 23.04,-92.16 v -46.08 c 0,-50.901 41.259,-92.16 92.16,-92.16 50.901,0 92.16,41.259 92.16,92.16 v 46.08 c 0,0 0,46.08 23.04,92.16 -23.04,0 -207.36,0 -230.4,0 z"
   id="path31861"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31871"
               style="fill:#1a1a1a">
	<g
   id="g31869"
   style="fill:#1a1a1a">
		<path
   d="m 428.809,108.16 -21.598,13.679 c 17.63,27.733 27.989,60.527 27.989,95.761 0,35.234 -10.359,68.028 -27.998,95.761 L 428.8,327.04 c 20.156,-31.701 32,-69.18 32,-109.44 0,-40.26 -11.844,-77.739 -31.991,-109.44 z"
   id="path31867"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31877"
               style="fill:#1a1a1a">
	<g
   id="g31875"
   style="fill:#1a1a1a">
		<path
   d="M 104.798,121.839 83.191,108.16 C 63.044,139.861 51.2,177.34 51.2,217.6 c 0,40.26 11.844,77.739 31.991,109.44 l 21.598,-13.679 C 87.159,285.628 76.8,252.834 76.8,217.6 c 0,-35.234 10.359,-68.028 27.998,-95.761 z"
   id="path31873"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31883"
               style="fill:#1a1a1a">
	<g
   id="g31881"
   style="fill:#1a1a1a">
		<path
   d="m 472.013,80.794 -21.606,13.679 c 22.664,35.669 35.993,77.832 35.993,123.127 0,45.295 -13.329,87.458 -35.994,123.127 l 21.606,13.679 C 497.195,314.778 512,267.93 512,217.6 512,167.27 497.195,120.422 472.013,80.794 Z"
   id="path31879"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31889"
               style="fill:#1a1a1a">
	<g
   id="g31887"
   style="fill:#1a1a1a">
		<path
   d="M 61.594,94.473 39.987,80.794 C 14.805,120.422 0,167.27 0,217.6 0,267.93 14.805,314.778 39.987,354.406 L 61.593,340.727 C 38.929,305.058 25.6,262.895 25.6,217.6 25.6,172.305 38.929,130.142 61.594,94.473 Z"
   id="path31885"
   style="fill:#1a1a1a" />
	</g>
</g><g
               id="g31891"
               style="fill:#1a1a1a">
</g><g
               id="g31893"
               style="fill:#1a1a1a">
</g><g
               id="g31895"
               style="fill:#1a1a1a">
</g><g
               id="g31897"
               style="fill:#1a1a1a">
</g><g
               id="g31899"
               style="fill:#1a1a1a">
</g><g
               id="g31901"
               style="fill:#1a1a1a">
</g><g
               id="g31903"
               style="fill:#1a1a1a">
</g><g
               id="g31905"
               style="fill:#1a1a1a">
</g><g
               id="g31907"
               style="fill:#1a1a1a">
</g><g
               id="g31909"
               style="fill:#1a1a1a">
</g><g
               id="g31911"
               style="fill:#1a1a1a">
</g><g
               id="g31913"
               style="fill:#1a1a1a">
</g><g
               id="g31915"
               style="fill:#1a1a1a">
</g><g
               id="g31917"
               style="fill:#1a1a1a">
</g><g
               id="g31919"
               style="fill:#1a1a1a">
</g></g></g></g></g><polygon
       style="display:none;fill:#00ff00;fill-opacity:1"
       points="26.305,3.123 32,8.818 11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 "
       id="checkConnect"
       transform="matrix(0.23686156,0,0,0.27836132,150.56012,179.47822)" /><path
       class="st2"
       d="m 109.3291,92.247843 v 5.477452 h -9.356781 v -5.477452 c 0,-0.431319 2.094681,-0.780286 4.677841,-0.780286 2.58425,0 4.67894,0.348967 4.67894,0.780286 z"
       id="path6949"
       style="font-size:15.1008px;fill:url(#SVGID_1_);stroke-width:0.106418" /><path
       class="st3"
       d="m 145.96079,92.247843 v 5.477452 h -9.35677 v -5.477452 c 0,-0.431319 2.09468,-0.780286 4.67784,-0.780286 2.58425,0 4.67893,0.348967 4.67893,0.780286 z"
       id="path6962"
       style="font-size:18px;fill:url(#SVGID_2_);stroke-width:0.106418" /><g
       id="g6978"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)">
				<path
   class="st4"
   d="m 106.57,18.04 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6964"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 106.57,22.79 c 0,0.36 -1.45,0.7 -4.04,1 -6.94,-0.81 -22.1,-1.38 -39.68,-1.38 -17.58,0 -32.73,0.57 -39.67,1.38 -2.59,-0.3 -4.04,-0.64 -4.04,-1 0,-1.31 19.57,-2.38 43.71,-2.38 24.15,0 43.72,1.07 43.72,2.38 z"
   id="path6966"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 106.57,27.53 c 0,0.36 -1.45,0.7 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.3 -4.04,-0.64 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6968"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 106.57,32.28 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6970"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 106.57,37.03 c 0,0.36 -1.45,0.7 -4.04,1 -6.94,-0.81 -22.1,-1.38 -39.68,-1.38 -17.58,0 -32.73,0.57 -39.67,1.38 -2.59,-0.3 -4.04,-0.64 -4.04,-1 0,-1.31 19.57,-2.38 43.71,-2.38 24.15,0 43.72,1.07 43.72,2.38 z"
   id="path6972"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 106.57,41.78 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.82 -22.1,-1.38 -39.68,-1.38 -17.58,0 -32.73,0.56 -39.67,1.38 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.32 19.57,-2.38 43.71,-2.38 24.15,0 43.72,1.06 43.72,2.38 z"
   id="path6974"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 106.57,46.52 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6976"
   style="font-size:21px;fill:#483a30" />
			</g><g
       id="g6994"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)">
				<path
   class="st4"
   d="m 439.54,18.04 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6980"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 439.54,22.79 c 0,0.36 -1.45,0.7 -4.04,1 -6.94,-0.81 -22.1,-1.38 -39.68,-1.38 -17.58,0 -32.73,0.57 -39.67,1.38 -2.59,-0.3 -4.04,-0.64 -4.04,-1 0,-1.31 19.57,-2.38 43.71,-2.38 24.15,0 43.72,1.07 43.72,2.38 z"
   id="path6982"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 439.54,27.53 c 0,0.36 -1.45,0.7 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.3 -4.04,-0.64 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6984"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 439.54,32.28 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6986"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 439.54,37.03 c 0,0.36 -1.45,0.7 -4.04,1 -6.94,-0.81 -22.1,-1.38 -39.68,-1.38 -17.58,0 -32.73,0.57 -39.67,1.38 -2.59,-0.3 -4.04,-0.64 -4.04,-1 0,-1.31 19.57,-2.38 43.71,-2.38 24.15,0 43.72,1.07 43.72,2.38 z"
   id="path6988"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 439.54,41.78 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.82 -22.1,-1.38 -39.68,-1.38 -17.58,0 -32.73,0.56 -39.67,1.38 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.32 19.57,-2.38 43.71,-2.38 24.15,0 43.72,1.06 43.72,2.38 z"
   id="path6990"
   style="font-size:21px;fill:#483a30" />
				<path
   class="st4"
   d="m 439.54,46.52 c 0,0.35 -1.45,0.69 -4.04,1 -6.94,-0.81 -22.1,-1.37 -39.68,-1.37 -17.58,0 -32.73,0.56 -39.67,1.37 -2.59,-0.31 -4.04,-0.65 -4.04,-1 0,-1.31 19.57,-2.37 43.71,-2.37 24.15,0 43.72,1.06 43.72,2.37 z"
   id="path6992"
   style="font-size:21px;fill:#483a30" />
			</g><path
       class="st5"
       d="M 146.49767,98.515876 V 117.8707 c 0,1.27647 -1.03414,2.31101 -2.31032,2.31101 h -42.35468 c -1.27617,0 -2.310312,-1.03454 -2.310312,-2.31101 V 98.515876 c 0,-1.27646 1.034142,-2.311008 2.310312,-2.311008 h 42.35468 c 1.27618,0 2.31032,1.034548 2.31032,2.311008 z"
       id="path6996"
       style="font-size:13.4951px;fill:#bdad9b;stroke-width:0.106418" /><path
       class="st6"
       d="m 144.78583,96.822508 v 21.189222 c 0,0.34176 -0.29594,0.61764 -0.66009,0.61764 h -42.23146 c -0.36415,0 -0.66009,-0.27588 -0.66009,-0.61764 V 96.822508 c 0,-0.341761 0.29594,-0.61764 0.66009,-0.61764 h 42.23146 c 0.36415,0 0.66009,0.275879 0.66009,0.61764 z"
       id="path7007"
       style="font-size:12.1191px;fill:url(#SVGID_3_);stroke-width:0.106418" /><path
       class="st7"
       d="m 143.21922,99.04293 v 18.71454 c 0,0.50337 -0.39495,0.91101 -0.88012,0.91101 h -38.65817 c -0.48627,0 -0.88012,-0.40764 -0.88012,-0.91101 V 99.04293 c 0,-0.503378 0.39385,-0.911021 0.88012,-0.911021 h 38.65817 c 0.48517,0 0.88012,0.407643 0.88012,0.911021 z"
       id="path7022"
       style="font-size:16.5828px;fill:url(#SVGID_4_);stroke-width:0.106418" /><path
       class="st8"
       d="m 109.06506,140.2705 v 1.71086 c 0,0.32633 -0.28274,0.59089 -0.63149,0.59089 h -1.82845 c -0.34874,0 -0.63148,-0.26456 -0.63148,-0.59089 v -1.71086 c 0,-0.32632 0.28274,-0.59088 0.63148,-0.59088 h 1.82845 c 0.34875,0 0.63149,0.26456 0.63149,0.59088 z"
       id="path7035"
       style="font-size:9.9589px;fill:url(#SVGID_5_);stroke-width:0.106418" /><path
       class="st9"
       d="m 139.08156,140.2705 v 1.71086 c 0,0.32633 -0.28384,0.59089 -0.63259,0.59089 h -1.82735 c -0.34875,0 -0.63149,-0.26456 -0.63149,-0.59089 v -1.71086 c 0,-0.32632 0.28274,-0.59088 0.63149,-0.59088 h 1.82735 c 0.34875,0 0.63259,0.26456 0.63259,0.59088 z"
       id="path7048"
       style="font-family:Calculator;fill:url(#SVGID_6_);stroke-width:0.106418" /><path
       class="st10"
       d="m 143.02229,141.59741 h -39.75062 c -0.79321,0 -1.4434,-0.58779 -1.472,-1.32897 l -0.77231,-20.15776 c -0.0297,-0.78029 0.63809,-1.42778 1.472,-1.42778 h 41.39095 c 0.83722,0 1.50501,0.65162 1.472,1.43396 l -0.86802,20.15776 c -0.0319,0.73911 -0.68099,1.32279 -1.472,1.32279 z"
       id="path7050"
       style="font-size:45.1149px;fill:#c3b3a2;stroke-width:0.106418" /><path
       class="st11"
       d="m 143.48326,120.71494 -0.88012,18.78866 c -0.0132,0.27588 -0.25524,0.49205 -0.55008,0.49205 H 104.2475 c -0.29594,0 -0.53907,-0.21823 -0.55007,-0.49411 l -0.79651,-19.1263 c -0.0132,-0.29338 0.23983,-0.53735 0.55447,-0.53426 l 39.48329,0.33662 c 0.31024,0.002 0.55778,0.24602 0.54458,0.53734 z"
       id="path7063"
       style="letter-spacing:4;fill:url(#SVGID_7_);stroke-width:0.106418" /><polygon
       class="st12"
       points="286.55,294.7 311.98,294.7 306.03,481.42 292.15,481.42 "
       id="polygon7076"
       style="font-size:23px;fill:url(#SVGID_8_)"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)" /><polygon
       class="st13"
       points="135.02,294.7 160.45,294.7 154.5,481.42 140.62,481.42 "
       id="polygon7089"
       style="fill:url(#SVGID_9_)"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)" /><path
       class="st14"
       d="m 147.51641,118.92481 v 0.60529 c 0,0.14617 -0.10892,0.27176 -0.26184,0.30162 l -1.25747,0.24911 c -1.91206,0.37882 -3.86263,0.57029 -5.8176,0.57029 h -33.44786 c -1.95387,0 -3.90223,-0.19147 -5.81429,-0.57029 l -1.765743,-0.35 c -0.152921,-0.0298 -0.261836,-0.15646 -0.261836,-0.30161 v -0.50544 c 0,-0.17088 0.147421,-0.30881 0.330045,-0.30881 h 47.965444 c 0.18373,0.001 0.33115,0.13896 0.33115,0.30984 z"
       id="path7091"
       style="fill:#c1b2a2;stroke-width:0.106418" /><path
       class="st15"
       d="m 147.19626,117.0338 c -6.5844,-0.14206 -15.10506,-0.22029 -23.99207,-0.22029 -8.88811,0 -17.40878,0.0782 -23.993175,0.22029 -0.210128,0.004 -0.375151,0.16264 -0.375151,0.36029 v 0.69279 c 0,0.19765 0.165023,0.35514 0.375151,0.36028 6.583295,0.14206 15.103965,0.2203 23.993175,0.2203 8.88701,0 17.40767,-0.0782 23.99207,-0.2203 0.21013,-0.004 0.37515,-0.16263 0.37515,-0.36028 v -0.69279 c 0,-0.19765 -0.16502,-0.35514 -0.37515,-0.36029 z"
       id="path7093"
       style="fill:#c6b8a8;stroke-width:0.106418" /><path
       class="st16"
       d="m 101.62584,117.03483 h -2.539142 v 0 c 0,-0.5178 0.447761,-0.93676 1.001132,-0.93676 h 0.53688 c 0.55337,0 1.00113,0.41896 1.00113,0.93676 z"
       id="path7095"
       style="fill:#dcd7cf;stroke-width:0.106418" /><g
       id="g7103"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)">
				<polygon
   class="st17"
   points="27.58,295.59 27.58,285.63 32.59,285.63 32.59,294.2 "
   id="polygon7097"
   style="fill:#a5a19b" />
				<polygon
   class="st17"
   points="14.52,294.2 14.52,285.63 19.14,285.63 19.14,295.48 "
   id="polygon7099"
   style="fill:#a5a19b" />
				<polygon
   class="st16"
   points="19.14,295.48 19.14,285.63 27.58,285.63 27.58,295.59 26.49,295.89 20.63,295.89 "
   id="polygon7101"
   style="fill:#dcd7cf" />
			</g><path
       class="st16"
       d="m 147.43389,117.03483 h -2.53914 v 0 c 0,-0.5178 0.44776,-0.93676 1.00113,-0.93676 h 0.53688 c 0.55337,0 1.00113,0.41896 1.00113,0.93676 z"
       id="path7105"
       style="fill:#dcd7cf;stroke-width:0.106418" /><g
       id="g7113"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)">
				<polygon
   class="st17"
   points="443.96,295.59 443.96,285.63 448.97,285.63 448.97,294.2 "
   id="polygon7107"
   style="fill:#a5a19b" />
				<polygon
   class="st17"
   points="430.9,294.2 430.9,285.63 435.52,285.63 435.52,295.48 "
   id="polygon7109"
   style="fill:#a5a19b" />
				<polygon
   class="st16"
   points="435.52,295.48 435.52,285.63 443.96,285.63 443.96,295.59 442.87,295.89 437.01,295.89 "
   id="polygon7111"
   style="fill:#dcd7cf" />
			</g><path
       class="st18"
       d="m 140.8902,103.90171 v 12.90253 c 0,0.90279 -0.80648,1.63572 -1.8024,1.63572 h -29.35445 c -0.99591,0 -1.8024,-0.73293 -1.8024,-1.63572 v -12.90253 c 0,-0.90382 0.80649,-1.63572 1.8024,-1.63572 h 29.35445 c 0.99592,0 1.8024,0.7319 1.8024,1.63572 z"
       id="path7115"
       style="fill:#89746a;stroke-width:0.108058" /><path
       class="st19"
       d="m 138.64711,104.89199 v 11.67446 c 0,0.6444 -0.61067,1.16735 -1.36322,1.16735 h -25.997 c -0.75373,0 -1.36322,-0.52295 -1.36322,-1.16735 v -11.67446 c 0,-0.6444 0.60949,-1.16733 1.36322,-1.16733 h 25.997 c 0.75255,0 1.36322,0.52293 1.36322,1.16733 z"
       id="path7117"
       style="fill:#382f2b;stroke-width:0.111241" /><path
       class="st20"
       d="m 137.76109,104.87552 v 11.95239 c 0,0.26045 -0.24591,0.47147 -0.5494,0.47147 h -26.03656 c -0.30349,0 -0.55059,-0.21102 -0.55059,-0.47147 v -11.95239 c 0,-0.26044 0.2471,-0.47146 0.55059,-0.47146 h 26.03656 c 0.30349,0 0.5494,0.21102 0.5494,0.47146 z"
       id="path7119"
       style="fill:#ece6e0;stroke-width:0.111123" /><ellipse
       class="st18"
       cx="139.23007"
       cy="104.74994"
       id="circle7121"
       style="fill:#89746a;stroke-width:0.106418"
       rx="2.1111882"
       ry="1.9754236" /><ellipse
       class="st21"
       cx="139.23007"
       cy="104.74994"
       id="circle7123"
       style="fill:#3c1d69;fill-opacity:1;stroke-width:0.106418"
       rx="1.6535256"
       ry="1.5471921" /><ellipse
       class="st18"
       cx="110.29393"
       cy="116.26793"
       id="circle7125"
       style="fill:#89746a;stroke-width:0.106418"
       rx="2.1287904"
       ry="1.991894" /><ellipse
       class="st21"
       cx="110.29393"
       cy="116.26793"
       id="circle7127"
       style="fill:#3c1d69;fill-opacity:1;stroke-width:0.106418"
       rx="1.6535256"
       ry="1.5471921" /><ellipse
       class="st16"
       cx="110.53376"
       cy="104.79008"
       id="circle7129"
       style="fill:#dcd7cf;stroke-width:0.106418"
       rx="1.0374416"
       ry="0.97072661" /><ellipse
       class="st16"
       cx="139.42039"
       cy="116.32865"
       id="circle7131"
       style="fill:#dcd7cf;stroke-width:0.106418"
       rx="1.0374416"
       ry="0.97072661" /><polygon
       class="st17"
       points="117.33,140.42 121.37,140.42 121.37,138.42 117.33,138.42 117.33,134.38 115.33,134.38 115.33,138.42 111.28,138.42 111.28,140.42 115.33,140.42 115.33,144.47 117.33,144.47 "
       id="polygon7133"
       style="fill:#a5a19b"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)" /><polygon
       class="st17"
       points="379.9,252.51 383.94,252.51 383.94,250.51 379.9,250.51 379.9,246.47 377.9,246.47 377.9,250.51 373.86,250.51 373.86,252.51 377.9,252.51 377.9,256.56 379.9,256.56 "
       id="polygon7135"
       style="fill:#a5a19b"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)" /><rect
       x="111.31906"
       y="113.41751"
       class="st22"
       width="25.515982"
       height="2.6465938"
       id="rect7137"
       style="fill:#44342f;stroke-width:0.107754" /><rect
       x="127.54986"
       y="113.40302"
       class="st23"
       width="9.6508808"
       height="2.6617115"
       id="rect7139"
       style="fill:#e41e27;stroke-width:0.122702" /><g
       id="g7249"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)">
				<path
   d="m 153.84,167.65 c 0,0.57 -0.13,1.08 -0.39,1.51 -0.26,0.44 -0.61,0.77 -1.05,1.01 l 1.14,1.23 -0.63,0.53 -1.27,-1.47 c -0.22,0.04 -0.43,0.06 -0.63,0.06 -0.4,0 -0.77,-0.07 -1.11,-0.21 -0.34,-0.14 -0.64,-0.34 -0.9,-0.6 -0.26,-0.26 -0.45,-0.56 -0.6,-0.91 -0.15,-0.35 -0.22,-0.73 -0.22,-1.15 0,-0.42 0.07,-0.8 0.22,-1.15 0.15,-0.35 0.35,-0.65 0.6,-0.91 0.25,-0.26 0.55,-0.45 0.9,-0.59 0.34,-0.14 0.71,-0.21 1.11,-0.21 0.4,0 0.77,0.07 1.12,0.21 0.35,0.14 0.65,0.34 0.9,0.59 0.25,0.25 0.45,0.56 0.6,0.91 0.13,0.35 0.21,0.73 0.21,1.15 z m -0.84,0 c 0,-0.31 -0.05,-0.59 -0.15,-0.84 -0.1,-0.25 -0.24,-0.47 -0.42,-0.66 -0.18,-0.19 -0.39,-0.33 -0.64,-0.43 -0.25,-0.1 -0.51,-0.15 -0.79,-0.15 -0.28,0 -0.54,0.05 -0.79,0.15 -0.24,0.1 -0.45,0.24 -0.63,0.43 -0.18,0.19 -0.32,0.4 -0.42,0.66 -0.1,0.26 -0.15,0.53 -0.15,0.84 0,0.31 0.05,0.59 0.15,0.85 0.1,0.26 0.24,0.48 0.42,0.66 0.18,0.18 0.39,0.32 0.63,0.43 0.24,0.11 0.5,0.15 0.79,0.15 0.28,0 0.54,-0.05 0.79,-0.15 0.25,-0.1 0.46,-0.24 0.64,-0.43 0.18,-0.18 0.32,-0.4 0.42,-0.66 0.1,-0.26 0.15,-0.54 0.15,-0.85 z"
   id="path7143" />
				<path
   d="m 159.4,166.33 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 H 160 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.56,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.47,-0.2 0.73,-0.2 z"
   id="path7145" />
				<path
   d="m 163.55,166.32 c 0.61,0 1.06,0.15 1.35,0.45 0.29,0.3 0.43,0.7 0.43,1.21 v 2.44 h -0.68 l -0.03,-0.48 h -0.02 c -0.15,0.18 -0.34,0.32 -0.56,0.43 -0.22,0.11 -0.48,0.16 -0.77,0.16 -0.24,0 -0.46,-0.04 -0.64,-0.11 -0.18,-0.07 -0.34,-0.17 -0.47,-0.29 -0.13,-0.12 -0.22,-0.26 -0.29,-0.43 -0.06,-0.16 -0.1,-0.33 -0.1,-0.52 0,-0.43 0.15,-0.75 0.44,-0.98 0.29,-0.23 0.71,-0.34 1.25,-0.34 0.17,0 0.35,0.01 0.52,0.02 0.18,0.02 0.35,0.04 0.52,0.06 v -0.11 c 0,-0.51 -0.32,-0.76 -0.96,-0.76 -0.21,0 -0.42,0.03 -0.64,0.08 -0.22,0.05 -0.43,0.14 -0.64,0.28 l -0.38,-0.67 c 0.25,-0.12 0.51,-0.23 0.8,-0.31 0.3,-0.09 0.59,-0.13 0.87,-0.13 z m -0.13,3.48 c 0.13,0 0.26,-0.02 0.39,-0.06 0.13,-0.04 0.25,-0.1 0.35,-0.18 0.1,-0.08 0.19,-0.19 0.26,-0.31 0.07,-0.12 0.1,-0.28 0.1,-0.45 v -0.11 c -0.18,-0.04 -0.34,-0.07 -0.49,-0.08 -0.15,-0.01 -0.3,-0.03 -0.45,-0.03 -0.29,0 -0.52,0.04 -0.71,0.13 -0.19,0.09 -0.28,0.25 -0.28,0.48 0,0.11 0.02,0.2 0.07,0.28 0.05,0.08 0.11,0.14 0.18,0.19 0.07,0.05 0.16,0.08 0.26,0.11 0.1,0.03 0.21,0.03 0.32,0.03 z"
   id="path7147" />
				<path
   d="m 170.41,170.41 h -1.05 l -1.05,-1.42 h -0.02 l -1.05,1.42 h -1.05 l 1.65,-2.02 -1.6,-1.97 h 1.01 l 1.05,1.36 h 0.02 l 1.05,-1.36 h 1.01 l -1.6,1.97 z"
   id="path7149" />
				<path
   d="m 176.64,167.54 h -3.76 v -0.69 h 3.76 z m 0,2.03 h -3.76 v -0.69 h 3.76 z"
   id="path7151" />
				<path
   d="m 181.35,166.84 c 0.26,0 0.51,0.05 0.73,0.14 0.22,0.09 0.42,0.22 0.59,0.39 0.17,0.17 0.3,0.36 0.39,0.58 0.09,0.22 0.14,0.47 0.14,0.73 0,0.26 -0.05,0.5 -0.14,0.73 -0.09,0.22 -0.23,0.42 -0.4,0.58 -0.17,0.16 -0.38,0.29 -0.62,0.39 -0.24,0.09 -0.52,0.14 -0.82,0.14 -0.3,0 -0.58,-0.05 -0.82,-0.14 -0.24,-0.09 -0.45,-0.22 -0.62,-0.39 -0.17,-0.17 -0.31,-0.36 -0.4,-0.58 -0.09,-0.22 -0.14,-0.47 -0.14,-0.73 0,-0.2 0.03,-0.39 0.08,-0.58 0.05,-0.19 0.15,-0.41 0.28,-0.68 0.14,-0.27 0.32,-0.6 0.54,-1 0.23,-0.4 0.52,-0.91 0.87,-1.54 h 0.93 c -0.24,0.43 -0.46,0.81 -0.66,1.14 -0.19,0.33 -0.37,0.62 -0.52,0.88 v 0.02 c 0.17,-0.06 0.37,-0.08 0.59,-0.08 z m -0.13,2.91 c 0.19,0 0.36,-0.03 0.5,-0.09 0.15,-0.06 0.27,-0.14 0.37,-0.24 0.1,-0.1 0.18,-0.21 0.23,-0.34 0.05,-0.13 0.08,-0.26 0.08,-0.4 0,-0.14 -0.03,-0.28 -0.08,-0.41 -0.05,-0.13 -0.13,-0.24 -0.23,-0.34 -0.1,-0.1 -0.22,-0.18 -0.37,-0.24 -0.15,-0.06 -0.31,-0.09 -0.5,-0.09 -0.19,0 -0.36,0.03 -0.5,0.09 -0.15,0.06 -0.27,0.14 -0.37,0.24 -0.1,0.1 -0.18,0.21 -0.23,0.34 -0.05,0.13 -0.08,0.26 -0.08,0.41 0,0.14 0.03,0.27 0.08,0.4 0.05,0.13 0.13,0.24 0.23,0.34 0.1,0.1 0.22,0.18 0.37,0.24 0.14,0.06 0.31,0.09 0.5,0.09 z"
   id="path7153" />
				<path
   d="m 190.42,166.33 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.45 -0.08,-0.77 -0.22,-0.96 -0.14,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.55,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.48,-0.2 0.74,-0.2 z"
   id="path7155" />
				<path
   d="m 196.64,168.87 c 0,0.52 -0.18,0.93 -0.53,1.22 -0.35,0.29 -0.83,0.43 -1.44,0.43 -0.33,0 -0.66,-0.06 -0.97,-0.18 -0.31,-0.12 -0.57,-0.3 -0.77,-0.52 l 0.57,-0.54 c 0.14,0.15 0.31,0.27 0.52,0.35 0.21,0.08 0.42,0.12 0.65,0.12 0.36,0 0.65,-0.08 0.85,-0.24 0.2,-0.16 0.31,-0.37 0.31,-0.63 0,-0.26 -0.1,-0.48 -0.29,-0.66 -0.19,-0.18 -0.5,-0.26 -0.91,-0.26 h -0.66 v -0.72 h 0.66 c 0.37,0 0.64,-0.08 0.83,-0.25 0.18,-0.17 0.28,-0.37 0.28,-0.62 0,-0.24 -0.09,-0.43 -0.26,-0.58 -0.18,-0.15 -0.44,-0.23 -0.81,-0.23 -0.23,0 -0.44,0.04 -0.64,0.12 -0.19,0.08 -0.36,0.19 -0.51,0.33 l -0.56,-0.58 c 0.22,-0.21 0.47,-0.36 0.76,-0.47 0.29,-0.11 0.6,-0.17 0.94,-0.17 0.31,0 0.58,0.04 0.81,0.12 0.24,0.08 0.43,0.19 0.59,0.33 0.16,0.14 0.28,0.3 0.36,0.49 0.08,0.19 0.12,0.38 0.12,0.59 0,0.27 -0.06,0.51 -0.19,0.72 -0.13,0.21 -0.31,0.38 -0.55,0.51 v 0.02 c 0.29,0.14 0.5,0.33 0.64,0.56 0.13,0.22 0.2,0.47 0.2,0.74 z"
   id="path7157" />
				<path
   d="m 199.56,170.91 -0.56,-0.21 2.42,-6.32 0.57,0.21 z"
   id="path7159" />
				<path
   d="m 204.85,166.33 c 0.21,0 0.4,0.04 0.59,0.11 0.19,0.07 0.35,0.18 0.49,0.32 0.14,0.14 0.26,0.33 0.34,0.55 0.08,0.22 0.13,0.48 0.13,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.23 -0.02,-0.42 -0.07,-0.58 -0.05,-0.16 -0.12,-0.29 -0.2,-0.39 -0.09,-0.1 -0.19,-0.17 -0.31,-0.21 -0.12,-0.04 -0.25,-0.06 -0.38,-0.06 -0.17,0 -0.32,0.03 -0.45,0.1 -0.13,0.07 -0.25,0.16 -0.35,0.27 -0.1,0.11 -0.17,0.25 -0.22,0.4 -0.05,0.15 -0.08,0.32 -0.08,0.49 v 2.07 h -0.81 v -5.88 h 0.81 v 2.31 h 0.02 c 0.18,-0.17 0.38,-0.3 0.61,-0.39 0.22,-0.1 0.45,-0.14 0.69,-0.14 z"
   id="path7161" />
				<path
   d="m 153.84,177.37 c 0,0.57 -0.13,1.08 -0.39,1.51 -0.26,0.44 -0.61,0.77 -1.05,1.01 l 1.14,1.23 -0.63,0.53 -1.27,-1.47 c -0.22,0.04 -0.43,0.06 -0.63,0.06 -0.4,0 -0.77,-0.07 -1.11,-0.21 -0.34,-0.14 -0.64,-0.34 -0.9,-0.6 -0.26,-0.26 -0.45,-0.56 -0.6,-0.91 -0.15,-0.35 -0.22,-0.73 -0.22,-1.15 0,-0.42 0.07,-0.8 0.22,-1.15 0.15,-0.35 0.35,-0.65 0.6,-0.91 0.25,-0.26 0.55,-0.45 0.9,-0.59 0.34,-0.14 0.71,-0.21 1.11,-0.21 0.4,0 0.77,0.07 1.12,0.21 0.35,0.14 0.65,0.34 0.9,0.59 0.25,0.25 0.45,0.56 0.6,0.91 0.13,0.35 0.21,0.74 0.21,1.15 z m -0.84,0 c 0,-0.31 -0.05,-0.59 -0.15,-0.84 -0.1,-0.25 -0.24,-0.47 -0.42,-0.66 -0.18,-0.19 -0.39,-0.33 -0.64,-0.43 -0.25,-0.1 -0.51,-0.15 -0.79,-0.15 -0.28,0 -0.54,0.05 -0.79,0.15 -0.24,0.1 -0.45,0.24 -0.63,0.43 -0.18,0.19 -0.32,0.4 -0.42,0.66 -0.1,0.26 -0.15,0.53 -0.15,0.84 0,0.31 0.05,0.59 0.15,0.85 0.1,0.26 0.24,0.48 0.42,0.66 0.18,0.18 0.39,0.32 0.63,0.43 0.24,0.11 0.5,0.15 0.79,0.15 0.28,0 0.54,-0.05 0.79,-0.15 0.25,-0.1 0.46,-0.24 0.64,-0.43 0.18,-0.18 0.32,-0.4 0.42,-0.66 0.1,-0.26 0.15,-0.54 0.15,-0.85 z"
   id="path7163" />
				<path
   d="m 159.4,176.05 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 H 160 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.56,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.47,-0.2 0.73,-0.2 z"
   id="path7165" />
				<path
   d="m 162.91,174.96 c 0,0.12 -0.04,0.23 -0.13,0.32 -0.09,0.09 -0.2,0.14 -0.34,0.14 -0.14,0 -0.25,-0.05 -0.34,-0.14 -0.09,-0.09 -0.13,-0.2 -0.13,-0.32 0,-0.13 0.04,-0.24 0.13,-0.33 0.09,-0.09 0.2,-0.14 0.34,-0.14 0.14,0 0.26,0.05 0.34,0.14 0.08,0.09 0.13,0.2 0.13,0.33 z m -0.89,5.18 h 0.81 v -3.99 h -0.81 z"
   id="path7167" />
				<path
   d="m 166.28,176.05 c 0.21,0 0.4,0.04 0.59,0.11 0.19,0.07 0.35,0.18 0.49,0.32 0.14,0.14 0.26,0.33 0.34,0.55 0.08,0.22 0.13,0.48 0.13,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.23 -0.02,-0.42 -0.07,-0.58 -0.05,-0.16 -0.12,-0.29 -0.2,-0.39 -0.09,-0.1 -0.19,-0.17 -0.31,-0.21 -0.12,-0.04 -0.25,-0.06 -0.38,-0.06 -0.17,0 -0.32,0.03 -0.45,0.1 -0.14,0.07 -0.25,0.16 -0.35,0.27 -0.1,0.11 -0.17,0.25 -0.22,0.4 -0.05,0.15 -0.08,0.32 -0.08,0.49 v 2.07 h -0.81 v -3.99 h 0.68 l 0.03,0.52 h 0.02 c 0.18,-0.21 0.4,-0.36 0.65,-0.46 0.25,-0.1 0.49,-0.17 0.75,-0.17 z"
   id="path7169" />
				<path
   d="m 174.34,177.27 h -3.76 v -0.69 h 3.76 z m 0,2.02 h -3.76 v -0.69 h 3.76 z"
   id="path7171" />
				<path
   d="m 178.92,174.51 c 0.31,0 0.6,0.06 0.85,0.19 0.26,0.12 0.47,0.31 0.65,0.55 0.18,0.24 0.32,0.54 0.41,0.9 0.1,0.36 0.15,0.77 0.15,1.23 0,0.46 -0.05,0.88 -0.15,1.23 -0.1,0.36 -0.23,0.66 -0.41,0.9 -0.18,0.24 -0.4,0.43 -0.65,0.55 -0.25,0.12 -0.54,0.19 -0.85,0.19 -0.32,0 -0.61,-0.06 -0.86,-0.19 -0.25,-0.13 -0.47,-0.31 -0.65,-0.55 -0.18,-0.24 -0.32,-0.54 -0.41,-0.9 -0.1,-0.36 -0.15,-0.77 -0.15,-1.23 0,-0.46 0.05,-0.88 0.15,-1.23 0.1,-0.36 0.24,-0.65 0.41,-0.9 0.18,-0.24 0.39,-0.42 0.65,-0.55 0.25,-0.13 0.54,-0.19 0.86,-0.19 z m 0,4.95 c 0.41,0 0.72,-0.17 0.94,-0.51 0.21,-0.34 0.32,-0.87 0.32,-1.58 0,-0.71 -0.11,-1.23 -0.32,-1.58 -0.21,-0.34 -0.53,-0.51 -0.94,-0.51 -0.41,0 -0.72,0.17 -0.94,0.51 -0.21,0.34 -0.32,0.87 -0.32,1.58 0,0.71 0.11,1.23 0.32,1.58 0.21,0.34 0.53,0.51 0.94,0.51 z"
   id="path7173" />
				<path
   d="m 182.59,179.34 c -0.04,0.16 -0.09,0.32 -0.15,0.5 -0.05,0.18 -0.11,0.35 -0.18,0.53 -0.06,0.18 -0.13,0.35 -0.19,0.51 -0.06,0.16 -0.13,0.31 -0.19,0.44 h -0.58 c 0.1,-0.34 0.2,-0.69 0.28,-1.04 0.08,-0.35 0.15,-0.66 0.19,-0.94 z"
   id="path7175" />
				<path
   d="m 185.22,174.51 c 0.31,0 0.6,0.06 0.85,0.19 0.26,0.12 0.47,0.31 0.65,0.55 0.18,0.24 0.32,0.54 0.41,0.9 0.1,0.36 0.15,0.77 0.15,1.23 0,0.46 -0.05,0.88 -0.15,1.23 -0.1,0.36 -0.23,0.66 -0.41,0.9 -0.18,0.24 -0.4,0.43 -0.65,0.55 -0.25,0.12 -0.54,0.19 -0.85,0.19 -0.32,0 -0.61,-0.06 -0.86,-0.19 -0.25,-0.13 -0.47,-0.31 -0.65,-0.55 -0.18,-0.24 -0.32,-0.54 -0.41,-0.9 -0.1,-0.36 -0.15,-0.77 -0.15,-1.23 0,-0.46 0.05,-0.88 0.15,-1.23 0.1,-0.36 0.24,-0.65 0.41,-0.9 0.18,-0.24 0.39,-0.42 0.65,-0.55 0.26,-0.13 0.54,-0.19 0.86,-0.19 z m 0,4.95 c 0.41,0 0.72,-0.17 0.94,-0.51 0.21,-0.34 0.32,-0.87 0.32,-1.58 0,-0.71 -0.11,-1.23 -0.32,-1.58 -0.21,-0.34 -0.53,-0.51 -0.94,-0.51 -0.41,0 -0.72,0.17 -0.94,0.51 -0.21,0.34 -0.32,0.87 -0.32,1.58 0,0.71 0.11,1.23 0.32,1.58 0.22,0.34 0.53,0.51 0.94,0.51 z"
   id="path7177" />
				<path
   d="m 192.08,178.99 h -0.74 v 1.14 h -0.81 v -1.14 h -2.55 l -0.07,-0.72 2.67,-3.66 h 0.75 v 3.64 h 0.74 v 0.74 z m -1.54,-0.74 v -2.29 h -0.02 l -1.65,2.27 v 0.02 z"
   id="path7179" />
				<path
   d="m 194.78,174.51 c 0.31,0 0.6,0.06 0.85,0.19 0.26,0.12 0.47,0.31 0.65,0.55 0.18,0.24 0.32,0.54 0.41,0.9 0.1,0.36 0.15,0.77 0.15,1.23 0,0.46 -0.05,0.88 -0.15,1.23 -0.1,0.36 -0.23,0.66 -0.41,0.9 -0.18,0.24 -0.4,0.43 -0.65,0.55 -0.25,0.12 -0.54,0.19 -0.85,0.19 -0.32,0 -0.61,-0.06 -0.86,-0.19 -0.25,-0.13 -0.47,-0.31 -0.65,-0.55 -0.18,-0.24 -0.32,-0.54 -0.41,-0.9 -0.1,-0.36 -0.15,-0.77 -0.15,-1.23 0,-0.46 0.05,-0.88 0.15,-1.23 0.1,-0.36 0.24,-0.65 0.41,-0.9 0.18,-0.24 0.39,-0.42 0.65,-0.55 0.26,-0.13 0.54,-0.19 0.86,-0.19 z m 0,4.95 c 0.41,0 0.72,-0.17 0.94,-0.51 0.21,-0.34 0.32,-0.87 0.32,-1.58 0,-0.71 -0.11,-1.23 -0.32,-1.58 -0.21,-0.34 -0.53,-0.51 -0.94,-0.51 -0.41,0 -0.72,0.17 -0.94,0.51 -0.21,0.34 -0.32,0.87 -0.32,1.58 0,0.71 0.11,1.23 0.32,1.58 0.22,0.34 0.53,0.51 0.94,0.51 z"
   id="path7181" />
				<path
   d="m 203.98,176.05 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.45 -0.08,-0.77 -0.22,-0.96 -0.14,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.55,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.48,-0.2 0.74,-0.2 z"
   id="path7183" />
				<path
   d="m 210.2,178.6 c 0,0.52 -0.18,0.93 -0.53,1.22 -0.35,0.29 -0.83,0.43 -1.44,0.43 -0.33,0 -0.66,-0.06 -0.97,-0.18 -0.31,-0.12 -0.57,-0.3 -0.77,-0.52 l 0.57,-0.54 c 0.14,0.15 0.31,0.27 0.52,0.35 0.21,0.08 0.42,0.12 0.65,0.12 0.36,0 0.65,-0.08 0.85,-0.24 0.2,-0.16 0.31,-0.37 0.31,-0.63 0,-0.26 -0.1,-0.48 -0.29,-0.66 -0.19,-0.18 -0.5,-0.26 -0.91,-0.26 h -0.66 v -0.72 h 0.66 c 0.37,0 0.64,-0.08 0.83,-0.25 0.18,-0.17 0.28,-0.37 0.28,-0.62 0,-0.24 -0.09,-0.43 -0.26,-0.58 -0.18,-0.15 -0.44,-0.23 -0.81,-0.23 -0.23,0 -0.44,0.04 -0.64,0.12 -0.19,0.08 -0.36,0.19 -0.51,0.33 l -0.56,-0.58 c 0.22,-0.21 0.47,-0.36 0.76,-0.47 0.29,-0.11 0.6,-0.17 0.94,-0.17 0.31,0 0.58,0.04 0.81,0.12 0.24,0.08 0.43,0.19 0.59,0.33 0.16,0.14 0.28,0.3 0.36,0.49 0.08,0.19 0.12,0.38 0.12,0.59 0,0.27 -0.06,0.51 -0.19,0.72 -0.13,0.21 -0.31,0.38 -0.55,0.51 v 0.02 c 0.29,0.14 0.5,0.33 0.64,0.56 0.14,0.21 0.2,0.47 0.2,0.74 z"
   id="path7185" />
				<path
   d="m 213.13,180.64 -0.57,-0.21 2.42,-6.32 0.57,0.21 z"
   id="path7187" />
				<path
   d="m 218.42,176.05 c 0.21,0 0.4,0.04 0.59,0.11 0.19,0.07 0.35,0.18 0.49,0.32 0.14,0.14 0.26,0.33 0.34,0.55 0.08,0.22 0.13,0.48 0.13,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.23 -0.02,-0.42 -0.07,-0.58 -0.05,-0.16 -0.12,-0.29 -0.2,-0.39 -0.09,-0.1 -0.19,-0.17 -0.31,-0.21 -0.12,-0.04 -0.25,-0.06 -0.38,-0.06 -0.17,0 -0.32,0.03 -0.45,0.1 -0.13,0.07 -0.25,0.16 -0.35,0.27 -0.1,0.11 -0.17,0.25 -0.22,0.4 -0.05,0.15 -0.08,0.32 -0.08,0.49 v 2.07 h -0.81 v -5.88 h 0.81 v 2.31 h 0.02 c 0.18,-0.17 0.38,-0.3 0.61,-0.39 0.21,-0.1 0.45,-0.14 0.69,-0.14 z"
   id="path7189" />
				<path
   d="m 152.61,186.05 c 0,0.25 -0.04,0.49 -0.12,0.7 -0.08,0.21 -0.2,0.39 -0.36,0.55 -0.16,0.15 -0.36,0.27 -0.59,0.36 -0.23,0.08 -0.5,0.13 -0.8,0.13 h -1.47 v 2.08 h -0.83 v -5.53 h 0.01 2.3 c 0.3,0 0.57,0.04 0.8,0.13 0.23,0.08 0.43,0.2 0.59,0.36 0.16,0.15 0.28,0.34 0.36,0.55 0.07,0.19 0.11,0.42 0.11,0.67 z m -0.84,0 c 0,-0.26 -0.08,-0.48 -0.24,-0.66 -0.16,-0.18 -0.43,-0.26 -0.79,-0.26 h -1.47 v 1.86 h 1.47 c 0.37,0 0.63,-0.09 0.79,-0.26 0.16,-0.19 0.24,-0.41 0.24,-0.68 z"
   id="path7191" />
				<path
   d="m 158.15,185.78 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.56,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.47,-0.2 0.73,-0.2 z"
   id="path7193" />
				<path
   d="m 162.31,185.77 c 0.61,0 1.06,0.15 1.35,0.45 0.29,0.3 0.43,0.7 0.43,1.21 v 2.44 h -0.68 l -0.03,-0.48 h -0.02 c -0.15,0.18 -0.34,0.32 -0.56,0.43 -0.22,0.11 -0.48,0.16 -0.77,0.16 -0.24,0 -0.46,-0.04 -0.64,-0.11 -0.18,-0.07 -0.34,-0.17 -0.47,-0.29 -0.13,-0.12 -0.22,-0.26 -0.29,-0.43 -0.06,-0.16 -0.1,-0.33 -0.1,-0.52 0,-0.43 0.15,-0.75 0.44,-0.98 0.29,-0.23 0.71,-0.34 1.25,-0.34 0.17,0 0.35,0.01 0.52,0.02 0.18,0.02 0.35,0.04 0.52,0.06 v -0.11 c 0,-0.51 -0.32,-0.76 -0.96,-0.76 -0.21,0 -0.42,0.03 -0.64,0.08 -0.22,0.05 -0.43,0.14 -0.64,0.28 l -0.38,-0.67 c 0.25,-0.12 0.51,-0.23 0.8,-0.31 0.29,-0.09 0.58,-0.13 0.87,-0.13 z m -0.13,3.47 c 0.13,0 0.26,-0.02 0.39,-0.06 0.13,-0.04 0.25,-0.1 0.35,-0.18 0.1,-0.08 0.19,-0.19 0.26,-0.31 0.07,-0.12 0.1,-0.28 0.1,-0.45 v -0.11 c -0.18,-0.04 -0.34,-0.07 -0.49,-0.08 -0.15,-0.01 -0.3,-0.03 -0.45,-0.03 -0.29,0 -0.52,0.04 -0.71,0.13 -0.19,0.09 -0.28,0.25 -0.28,0.48 0,0.11 0.02,0.2 0.07,0.28 0.05,0.08 0.11,0.14 0.18,0.19 0.07,0.05 0.16,0.08 0.26,0.11 0.1,0.03 0.2,0.03 0.32,0.03 z"
   id="path7195" />
				<path
   d="m 169.16,189.86 h -1.05 l -1.05,-1.42 h -0.02 l -1.05,1.42 h -1.05 l 1.65,-2.02 -1.6,-1.97 H 166 l 1.05,1.36 h 0.02 l 1.05,-1.36 h 1.01 l -1.6,1.97 z"
   id="path7197" />
				<path
   d="m 175.39,186.99 h -3.76 v -0.69 h 3.76 z m 0,2.03 h -3.76 v -0.69 h 3.76 z"
   id="path7199" />
				<path
   d="m 181.75,189.12 v 0.74 h -3.53 v -0.67 l 1.82,-1.94 c 0.14,-0.15 0.26,-0.28 0.37,-0.4 0.11,-0.12 0.2,-0.24 0.28,-0.34 0.08,-0.11 0.13,-0.21 0.17,-0.32 0.04,-0.11 0.06,-0.21 0.06,-0.33 0,-0.24 -0.08,-0.45 -0.23,-0.61 -0.15,-0.17 -0.38,-0.25 -0.68,-0.25 -0.3,0 -0.53,0.09 -0.68,0.28 -0.15,0.19 -0.25,0.43 -0.3,0.73 l -0.83,-0.23 c 0.08,-0.46 0.27,-0.84 0.58,-1.12 0.31,-0.28 0.72,-0.43 1.24,-0.43 0.29,0 0.54,0.04 0.75,0.13 0.21,0.09 0.39,0.21 0.54,0.36 0.14,0.15 0.25,0.32 0.32,0.52 0.07,0.2 0.11,0.41 0.11,0.63 0,0.17 -0.03,0.33 -0.08,0.48 -0.05,0.15 -0.12,0.3 -0.21,0.45 -0.09,0.15 -0.2,0.29 -0.32,0.44 -0.12,0.15 -0.26,0.29 -0.41,0.45 l -1.34,1.43 v 0.02 h 2.37 z"
   id="path7201" />
				<path
   d="m 184.75,184.24 c 0.31,0 0.6,0.06 0.85,0.19 0.26,0.12 0.47,0.31 0.65,0.55 0.18,0.24 0.32,0.54 0.41,0.9 0.1,0.36 0.15,0.77 0.15,1.23 0,0.46 -0.05,0.88 -0.15,1.23 -0.1,0.36 -0.23,0.66 -0.41,0.9 -0.18,0.24 -0.4,0.43 -0.65,0.55 -0.25,0.12 -0.54,0.19 -0.85,0.19 -0.32,0 -0.61,-0.06 -0.86,-0.19 -0.25,-0.13 -0.47,-0.31 -0.65,-0.55 -0.18,-0.24 -0.32,-0.54 -0.41,-0.9 -0.1,-0.36 -0.15,-0.77 -0.15,-1.23 0,-0.46 0.05,-0.88 0.15,-1.23 0.1,-0.36 0.24,-0.65 0.41,-0.9 0.18,-0.24 0.39,-0.42 0.65,-0.55 0.26,-0.13 0.54,-0.19 0.86,-0.19 z m 0,4.95 c 0.41,0 0.72,-0.17 0.94,-0.51 0.21,-0.34 0.32,-0.87 0.32,-1.58 0,-0.71 -0.11,-1.23 -0.32,-1.58 -0.21,-0.34 -0.53,-0.51 -0.94,-0.51 -0.41,0 -0.72,0.17 -0.94,0.51 -0.21,0.34 -0.32,0.87 -0.32,1.58 0,0.71 0.11,1.23 0.32,1.58 0.22,0.34 0.53,0.51 0.94,0.51 z"
   id="path7203" />
				<path
   d="m 190.34,189.86 h -0.83 v -5.53 h 0.83 v 2.54 h 0.02 l 2.5,-2.54 H 194 l -2.53,2.5 2.62,3.03 h -1.12 l -2.08,-2.51 -0.55,0.48 z"
   id="path7205" />
				<path
   d="m 199.13,186.05 c 0,0.25 -0.04,0.49 -0.12,0.7 -0.08,0.21 -0.2,0.39 -0.36,0.55 -0.16,0.15 -0.36,0.27 -0.59,0.36 -0.23,0.08 -0.5,0.13 -0.8,0.13 h -1.47 v 2.08 h -0.83 v -5.53 h 0.01 2.3 c 0.3,0 0.57,0.04 0.8,0.13 0.23,0.08 0.43,0.2 0.59,0.36 0.16,0.15 0.28,0.34 0.36,0.55 0.07,0.19 0.11,0.42 0.11,0.67 z m -0.85,0 c 0,-0.26 -0.08,-0.48 -0.24,-0.66 -0.16,-0.18 -0.43,-0.26 -0.79,-0.26 h -1.47 v 1.86 h 1.47 c 0.37,0 0.63,-0.09 0.79,-0.26 0.16,-0.19 0.24,-0.41 0.24,-0.68 z"
   id="path7207" />
				<path
   d="m 201.71,185.77 c 0.61,0 1.06,0.15 1.35,0.45 0.29,0.3 0.43,0.7 0.43,1.21 v 2.44 h -0.68 l -0.03,-0.48 h -0.02 c -0.15,0.18 -0.34,0.32 -0.56,0.43 -0.22,0.11 -0.48,0.16 -0.77,0.16 -0.24,0 -0.46,-0.04 -0.64,-0.11 -0.18,-0.07 -0.34,-0.17 -0.47,-0.29 -0.13,-0.12 -0.22,-0.26 -0.29,-0.43 -0.06,-0.16 -0.1,-0.33 -0.1,-0.52 0,-0.43 0.15,-0.75 0.44,-0.98 0.29,-0.23 0.71,-0.34 1.25,-0.34 0.17,0 0.35,0.01 0.52,0.02 0.18,0.02 0.35,0.04 0.52,0.06 v -0.11 c 0,-0.51 -0.32,-0.76 -0.96,-0.76 -0.21,0 -0.42,0.03 -0.64,0.08 -0.22,0.05 -0.43,0.14 -0.64,0.28 l -0.38,-0.67 c 0.25,-0.12 0.51,-0.23 0.8,-0.31 0.3,-0.09 0.58,-0.13 0.87,-0.13 z m -0.13,3.47 c 0.13,0 0.26,-0.02 0.39,-0.06 0.13,-0.04 0.25,-0.1 0.35,-0.18 0.1,-0.08 0.19,-0.19 0.25,-0.31 0.06,-0.12 0.1,-0.28 0.1,-0.45 v -0.11 c -0.18,-0.04 -0.34,-0.07 -0.49,-0.08 -0.15,-0.02 -0.3,-0.03 -0.45,-0.03 -0.29,0 -0.52,0.04 -0.71,0.13 -0.19,0.09 -0.28,0.25 -0.28,0.48 0,0.11 0.02,0.2 0.07,0.28 0.05,0.08 0.11,0.14 0.18,0.19 0.08,0.05 0.16,0.08 0.26,0.11 0.11,0.02 0.22,0.03 0.33,0.03 z"
   id="path7209" />
				<path
   d="m 153.46,194.06 -2.42,5.53 h -0.74 l -2.41,-5.53 h 0.93 l 0.83,1.95 c 0.06,0.14 0.13,0.32 0.22,0.53 0.09,0.21 0.18,0.44 0.27,0.67 0.09,0.23 0.18,0.46 0.28,0.69 0.1,0.23 0.17,0.42 0.24,0.59 h 0.02 c 0.06,-0.17 0.14,-0.36 0.23,-0.59 0.09,-0.23 0.18,-0.46 0.28,-0.69 0.09,-0.23 0.18,-0.45 0.27,-0.67 0.09,-0.21 0.16,-0.39 0.22,-0.53 l 0.83,-1.95 z"
   id="path7211" />
				<path
   d="m 156.41,195.5 c 0.21,0 0.4,0.04 0.59,0.11 0.19,0.07 0.35,0.18 0.49,0.32 0.14,0.14 0.26,0.33 0.34,0.55 0.08,0.22 0.13,0.48 0.13,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.23 -0.02,-0.42 -0.07,-0.58 -0.05,-0.16 -0.12,-0.29 -0.2,-0.39 -0.09,-0.1 -0.19,-0.17 -0.31,-0.21 -0.12,-0.04 -0.25,-0.06 -0.38,-0.06 -0.17,0 -0.32,0.03 -0.45,0.1 -0.14,0.07 -0.25,0.16 -0.35,0.27 -0.1,0.11 -0.17,0.25 -0.22,0.4 -0.05,0.15 -0.08,0.32 -0.08,0.49 v 2.07 h -0.81 v -3.99 h 0.68 l 0.03,0.52 H 155 c 0.18,-0.21 0.4,-0.36 0.65,-0.46 0.25,-0.1 0.5,-0.17 0.76,-0.17 z"
   id="path7213" />
				<path
   d="m 164.48,196.71 h -3.76 v -0.69 h 3.76 z m 0,2.03 h -3.76 v -0.69 h 3.76 z"
   id="path7215" />
				<path
   d="m 169.84,194.02 v 5.57 h -0.81 v -4.69 l -1.19,0.5 -0.24,-0.7 1.6,-0.68 z"
   id="path7217" />
				<path
   d="m 172.73,198.79 c -0.04,0.16 -0.09,0.32 -0.15,0.5 -0.05,0.18 -0.11,0.35 -0.18,0.53 -0.06,0.18 -0.13,0.35 -0.19,0.51 -0.06,0.16 -0.13,0.31 -0.19,0.44 h -0.58 c 0.1,-0.34 0.2,-0.69 0.28,-1.04 0.08,-0.35 0.15,-0.66 0.19,-0.94 z"
   id="path7219" />
				<path
   d="m 177.13,198.85 v 0.74 h -3.53 v -0.67 l 1.82,-1.94 c 0.14,-0.15 0.26,-0.28 0.37,-0.4 0.11,-0.12 0.2,-0.24 0.28,-0.34 0.08,-0.11 0.13,-0.21 0.17,-0.32 0.04,-0.11 0.06,-0.21 0.06,-0.33 0,-0.24 -0.08,-0.45 -0.23,-0.61 -0.15,-0.17 -0.38,-0.25 -0.68,-0.25 -0.3,0 -0.53,0.09 -0.68,0.28 -0.15,0.19 -0.25,0.43 -0.3,0.73 l -0.83,-0.23 c 0.08,-0.46 0.27,-0.84 0.58,-1.12 0.31,-0.28 0.72,-0.43 1.24,-0.43 0.29,0 0.54,0.04 0.75,0.13 0.21,0.09 0.39,0.21 0.54,0.36 0.14,0.15 0.25,0.32 0.32,0.52 0.07,0.2 0.11,0.41 0.11,0.63 0,0.17 -0.03,0.33 -0.08,0.48 -0.05,0.15 -0.12,0.3 -0.21,0.45 -0.09,0.15 -0.2,0.29 -0.32,0.44 -0.12,0.15 -0.26,0.29 -0.41,0.45 l -1.35,1.43 v 0.02 h 2.38 z"
   id="path7221" />
				<path
   d="m 183.95,193.71 v 5.88 h -0.68 l -0.03,-0.53 h -0.02 c -0.18,0.2 -0.39,0.35 -0.63,0.47 -0.24,0.12 -0.51,0.17 -0.8,0.17 -0.28,0 -0.53,-0.05 -0.77,-0.15 -0.24,-0.1 -0.45,-0.25 -0.62,-0.43 -0.17,-0.18 -0.31,-0.41 -0.41,-0.66 -0.1,-0.26 -0.15,-0.54 -0.15,-0.84 0,-0.3 0.05,-0.58 0.15,-0.84 0.1,-0.26 0.24,-0.48 0.41,-0.66 0.17,-0.18 0.38,-0.33 0.62,-0.44 0.24,-0.11 0.5,-0.16 0.77,-0.16 0.26,0 0.51,0.05 0.74,0.14 0.23,0.09 0.43,0.23 0.6,0.4 h 0.02 v -2.33 h 0.8 z m -2.06,5.2 c 0.18,0 0.35,-0.03 0.51,-0.1 0.15,-0.06 0.29,-0.16 0.4,-0.27 0.11,-0.12 0.2,-0.25 0.26,-0.42 0.06,-0.16 0.09,-0.34 0.09,-0.54 0,-0.19 -0.03,-0.37 -0.09,-0.54 -0.06,-0.17 -0.15,-0.31 -0.26,-0.42 -0.11,-0.12 -0.24,-0.21 -0.4,-0.27 -0.16,-0.06 -0.32,-0.1 -0.51,-0.1 -0.18,0 -0.34,0.03 -0.5,0.1 -0.15,0.06 -0.29,0.16 -0.4,0.27 -0.11,0.12 -0.2,0.26 -0.26,0.42 -0.06,0.16 -0.09,0.34 -0.09,0.54 0,0.19 0.03,0.37 0.09,0.54 0.06,0.17 0.15,0.3 0.26,0.42 0.11,0.12 0.24,0.21 0.4,0.27 0.16,0.07 0.32,0.1 0.5,0.1 z"
   id="path7223" />
				<path
   d="m 189.73,195.5 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.45 -0.08,-0.77 -0.22,-0.96 -0.14,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.55,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.48,-0.2 0.74,-0.2 z"
   id="path7225" />
				<path
   d="m 150.92,203.74 v 5.57 h -0.81 v -4.69 l -1.19,0.5 -0.24,-0.7 1.6,-0.68 z"
   id="path7227" />
				<path
   d="m 154.05,209.31 h -0.83 v -5.53 h 0.83 z"
   id="path7229" />
				<path
   d="m 159.87,205.22 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.56,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.47,-0.2 0.73,-0.2 z"
   id="path7231" />
				<path
   d="m 164.66,205.22 c 0.28,0 0.53,0.05 0.77,0.16 0.24,0.11 0.45,0.25 0.62,0.44 0.17,0.19 0.31,0.41 0.41,0.66 0.1,0.26 0.15,0.54 0.15,0.84 0,0.3 -0.05,0.58 -0.15,0.84 -0.1,0.26 -0.24,0.48 -0.41,0.66 -0.17,0.18 -0.38,0.33 -0.62,0.43 -0.24,0.1 -0.5,0.15 -0.77,0.15 -0.27,0 -0.51,-0.05 -0.74,-0.14 -0.23,-0.09 -0.43,-0.22 -0.6,-0.4 h -0.02 v 2.06 h -0.81 v -5.61 h 0.68 l 0.03,0.53 h 0.02 c 0.18,-0.2 0.39,-0.36 0.63,-0.47 0.25,-0.1 0.51,-0.15 0.81,-0.15 z m -0.12,3.41 c 0.18,0 0.35,-0.03 0.51,-0.1 0.15,-0.06 0.29,-0.16 0.4,-0.27 0.11,-0.12 0.2,-0.25 0.26,-0.42 0.06,-0.17 0.09,-0.34 0.09,-0.54 0,-0.19 -0.03,-0.37 -0.09,-0.53 -0.06,-0.16 -0.15,-0.3 -0.26,-0.42 -0.11,-0.12 -0.24,-0.21 -0.4,-0.27 -0.15,-0.06 -0.32,-0.1 -0.51,-0.1 -0.18,0 -0.34,0.03 -0.5,0.1 -0.15,0.06 -0.29,0.16 -0.4,0.27 -0.11,0.12 -0.2,0.26 -0.26,0.42 -0.06,0.16 -0.09,0.34 -0.09,0.53 0,0.19 0.03,0.37 0.09,0.54 0.06,0.16 0.15,0.3 0.26,0.42 0.11,0.12 0.24,0.21 0.4,0.27 0.16,0.07 0.32,0.1 0.5,0.1 z"
   id="path7233" />
				<path
   d="m 172.96,206.44 h -3.76 v -0.69 h 3.76 z m 0,2.02 h -3.76 v -0.69 h 3.76 z"
   id="path7235" />
				<path
   d="m 177.54,203.68 c 0.31,0 0.6,0.06 0.85,0.19 0.26,0.12 0.47,0.31 0.65,0.55 0.18,0.24 0.32,0.54 0.41,0.9 0.1,0.36 0.15,0.77 0.15,1.23 0,0.46 -0.05,0.88 -0.15,1.23 -0.1,0.36 -0.23,0.66 -0.41,0.9 -0.18,0.24 -0.4,0.43 -0.65,0.55 -0.25,0.12 -0.54,0.19 -0.85,0.19 -0.32,0 -0.61,-0.06 -0.86,-0.19 -0.25,-0.13 -0.47,-0.31 -0.65,-0.55 -0.18,-0.24 -0.32,-0.54 -0.41,-0.9 -0.1,-0.36 -0.15,-0.77 -0.15,-1.23 0,-0.46 0.05,-0.88 0.15,-1.23 0.1,-0.36 0.24,-0.65 0.41,-0.9 0.18,-0.24 0.39,-0.42 0.65,-0.55 0.25,-0.12 0.54,-0.19 0.86,-0.19 z m 0,4.95 c 0.41,0 0.72,-0.17 0.94,-0.51 0.21,-0.34 0.32,-0.87 0.32,-1.58 0,-0.71 -0.11,-1.23 -0.32,-1.58 -0.21,-0.34 -0.53,-0.51 -0.94,-0.51 -0.41,0 -0.72,0.17 -0.94,0.51 -0.21,0.34 -0.32,0.87 -0.32,1.58 0,0.71 0.11,1.23 0.32,1.58 0.22,0.34 0.53,0.51 0.94,0.51 z"
   id="path7237" />
				<path
   d="m 181.21,208.51 c -0.04,0.16 -0.09,0.32 -0.15,0.5 -0.05,0.18 -0.11,0.35 -0.18,0.53 -0.06,0.18 -0.13,0.35 -0.19,0.51 -0.06,0.16 -0.13,0.31 -0.19,0.44 h -0.58 c 0.1,-0.34 0.2,-0.69 0.28,-1.04 0.08,-0.35 0.15,-0.66 0.19,-0.94 z"
   id="path7239" />
				<path
   d="m 183.84,203.68 c 0.31,0 0.6,0.06 0.85,0.19 0.26,0.12 0.47,0.31 0.65,0.55 0.18,0.24 0.32,0.54 0.41,0.9 0.1,0.36 0.15,0.77 0.15,1.23 0,0.46 -0.05,0.88 -0.15,1.23 -0.1,0.36 -0.23,0.66 -0.41,0.9 -0.18,0.24 -0.4,0.43 -0.65,0.55 -0.25,0.12 -0.54,0.19 -0.85,0.19 -0.32,0 -0.61,-0.06 -0.86,-0.19 -0.25,-0.13 -0.47,-0.31 -0.65,-0.55 -0.18,-0.24 -0.32,-0.54 -0.41,-0.9 -0.1,-0.36 -0.15,-0.77 -0.15,-1.23 0,-0.46 0.05,-0.88 0.15,-1.23 0.1,-0.36 0.24,-0.65 0.41,-0.9 0.18,-0.24 0.39,-0.42 0.65,-0.55 0.26,-0.12 0.54,-0.19 0.86,-0.19 z m 0,4.95 c 0.41,0 0.72,-0.17 0.94,-0.51 0.21,-0.34 0.32,-0.87 0.32,-1.58 0,-0.71 -0.11,-1.23 -0.32,-1.58 -0.21,-0.34 -0.53,-0.51 -0.94,-0.51 -0.41,0 -0.72,0.17 -0.94,0.51 -0.21,0.34 -0.32,0.87 -0.32,1.58 0,0.71 0.11,1.23 0.32,1.58 0.22,0.34 0.53,0.51 0.94,0.51 z"
   id="path7241" />
				<path
   d="m 189.41,203.74 v 5.57 h -0.81 v -4.69 l -1.19,0.5 -0.24,-0.7 1.6,-0.68 z"
   id="path7243" />
				<path
   d="m 197.82,205.22 c 0.19,0 0.38,0.04 0.55,0.11 0.17,0.07 0.32,0.18 0.45,0.32 0.13,0.14 0.23,0.33 0.3,0.55 0.08,0.22 0.11,0.48 0.11,0.79 v 2.33 h -0.81 v -2.09 c 0,-0.45 -0.08,-0.77 -0.22,-0.96 -0.14,-0.19 -0.34,-0.28 -0.59,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -2.09 c 0,-0.45 -0.07,-0.77 -0.22,-0.96 -0.15,-0.19 -0.34,-0.28 -0.58,-0.28 -0.3,0 -0.53,0.11 -0.69,0.34 -0.16,0.23 -0.23,0.52 -0.23,0.88 v 2.11 h -0.81 v -3.99 h 0.68 l 0.03,0.45 h 0.02 c 0.16,-0.18 0.34,-0.32 0.55,-0.41 0.21,-0.09 0.43,-0.13 0.66,-0.13 0.25,0 0.48,0.06 0.68,0.17 0.21,0.11 0.37,0.29 0.49,0.54 h 0.02 c 0.16,-0.24 0.36,-0.41 0.6,-0.53 0.24,-0.12 0.48,-0.2 0.74,-0.2 z"
   id="path7245" />
				<path
   d="m 204.04,207.77 c 0,0.52 -0.18,0.93 -0.53,1.22 -0.35,0.29 -0.83,0.43 -1.44,0.43 -0.33,0 -0.66,-0.06 -0.97,-0.18 -0.31,-0.12 -0.57,-0.3 -0.77,-0.52 l 0.57,-0.54 c 0.14,0.15 0.31,0.27 0.52,0.35 0.21,0.08 0.42,0.12 0.65,0.12 0.36,0 0.65,-0.08 0.85,-0.24 0.2,-0.16 0.31,-0.37 0.31,-0.63 0,-0.26 -0.1,-0.48 -0.29,-0.66 -0.19,-0.18 -0.5,-0.26 -0.91,-0.26 h -0.66 v -0.72 h 0.66 c 0.37,0 0.64,-0.08 0.83,-0.25 0.18,-0.17 0.28,-0.37 0.28,-0.62 0,-0.24 -0.09,-0.43 -0.26,-0.58 -0.18,-0.15 -0.44,-0.23 -0.81,-0.23 -0.23,0 -0.44,0.04 -0.64,0.12 -0.19,0.08 -0.36,0.19 -0.51,0.33 l -0.56,-0.58 c 0.22,-0.21 0.47,-0.36 0.76,-0.47 0.29,-0.11 0.6,-0.17 0.94,-0.17 0.31,0 0.58,0.04 0.81,0.12 0.24,0.08 0.43,0.19 0.59,0.33 0.16,0.14 0.28,0.3 0.36,0.49 0.08,0.19 0.12,0.38 0.12,0.59 0,0.27 -0.06,0.51 -0.19,0.72 -0.13,0.21 -0.31,0.38 -0.55,0.51 v 0.02 c 0.29,0.14 0.5,0.33 0.64,0.56 0.14,0.21 0.2,0.47 0.2,0.74 z"
   id="path7247" />
			</g><g
       id="g7277"
       transform="matrix(0.11001501,0,0,0.10294025,97.735714,90.438154)">
				<g
   id="g7275">
					<path
   d="m 153.18,148.92 c 0,0.53 -0.18,0.93 -0.54,1.22 -0.36,0.29 -0.86,0.43 -1.49,0.43 h -2.62 -0.02 v -6.11 h 2.42 c 0.63,0 1.13,0.14 1.49,0.43 0.36,0.29 0.54,0.68 0.54,1.19 0,0.32 -0.07,0.59 -0.21,0.81 -0.14,0.23 -0.33,0.41 -0.59,0.54 0.32,0.13 0.57,0.31 0.75,0.56 0.18,0.24 0.27,0.55 0.27,0.93 z m -1.16,-2.72 c 0,-0.25 -0.09,-0.46 -0.26,-0.62 -0.18,-0.16 -0.46,-0.24 -0.86,-0.24 h -1.47 v 1.72 h 1.47 c 0.38,0 0.66,-0.08 0.85,-0.24 0.18,-0.16 0.27,-0.36 0.27,-0.62 z m 0.23,2.61 c 0,-0.28 -0.09,-0.5 -0.28,-0.65 -0.19,-0.15 -0.47,-0.23 -0.87,-0.23 h -1.67 v 1.76 h 1.67 c 0.37,0 0.65,-0.08 0.85,-0.23 0.2,-0.15 0.3,-0.37 0.3,-0.65 z"
   id="path7251" />
					<path
   d="m 155.31,150.57 h -0.92 v -6.11 h 0.92 v 2.8 h 0.02 l 2.77,-2.8 h 1.26 l -2.79,2.76 2.89,3.35 h -1.24 l -2.3,-2.78 -0.61,0.53 z"
   id="path7253" />
					<path
   d="m 160.04,147.76 h 2.37 v 0.76 h -2.37 z"
   id="path7255" />
					<path
   d="m 166.42,150.68 c -0.44,0 -0.85,-0.08 -1.24,-0.24 -0.38,-0.16 -0.71,-0.38 -0.99,-0.66 -0.28,-0.28 -0.5,-0.61 -0.66,-1 -0.16,-0.39 -0.24,-0.81 -0.24,-1.27 0,-0.46 0.08,-0.88 0.24,-1.27 0.16,-0.39 0.38,-0.72 0.66,-1 0.28,-0.28 0.61,-0.5 0.99,-0.65 0.38,-0.16 0.79,-0.23 1.24,-0.23 0.48,0 0.93,0.09 1.34,0.26 0.41,0.18 0.76,0.43 1.04,0.77 l -0.7,0.62 c -0.2,-0.24 -0.44,-0.44 -0.73,-0.57 -0.29,-0.14 -0.61,-0.21 -0.95,-0.21 -0.32,0 -0.61,0.06 -0.88,0.17 -0.27,0.11 -0.5,0.27 -0.7,0.47 -0.2,0.2 -0.35,0.44 -0.47,0.73 -0.11,0.28 -0.17,0.59 -0.17,0.93 0,0.34 0.06,0.65 0.17,0.94 0.11,0.28 0.27,0.53 0.47,0.73 0.2,0.2 0.43,0.36 0.7,0.47 0.27,0.11 0.56,0.17 0.88,0.17 0.24,0 0.49,-0.02 0.75,-0.08 0.25,-0.05 0.51,-0.13 0.76,-0.24 v -1.42 h -1.44 v -0.88 h 2.36 v 2.79 c -0.14,0.09 -0.3,0.18 -0.48,0.26 -0.18,0.08 -0.37,0.16 -0.58,0.22 -0.21,0.07 -0.42,0.12 -0.65,0.16 -0.23,0.02 -0.47,0.03 -0.72,0.03 z"
   id="path7257" />
					<path
   d="m 174.47,149.31 h -0.81 v 1.26 h -0.9 v -1.26 h -2.82 l -0.07,-0.8 2.96,-4.05 h 0.83 v 4.02 h 0.81 z m -1.71,-0.83 v -2.53 h -0.02 l -1.83,2.51 v 0.02 z"
   id="path7259" />
					<path
   d="m 183.99,150.57 h -0.93 l -0.19,-3.19 c -0.02,-0.26 -0.03,-0.5 -0.04,-0.72 -0.01,-0.22 -0.02,-0.42 -0.03,-0.61 h -0.02 c -0.13,0.23 -0.23,0.42 -0.31,0.57 -0.08,0.15 -0.15,0.28 -0.21,0.37 -0.07,0.11 -0.12,0.19 -0.15,0.26 l -1.4,2.27 h -0.09 l -1.41,-2.27 c -0.04,-0.07 -0.09,-0.15 -0.15,-0.26 -0.05,-0.1 -0.12,-0.22 -0.21,-0.37 -0.09,-0.15 -0.19,-0.34 -0.31,-0.57 h -0.02 c -0.01,0.19 -0.02,0.39 -0.03,0.61 -0.01,0.22 -0.02,0.46 -0.03,0.72 l -0.19,3.19 h -0.94 l 0.38,-6.11 h 0.86 l 2.09,3.39 h 0.02 l 2.09,-3.39 h 0.85 z"
   id="path7261" />
					<path
   d="m 189.6,145.34 h -1.9 v 5.23 h -0.92 v -5.23 h -1.9 v -0.88 h 4.72 z"
   id="path7263" />
					<path
   d="m 194.52,148.87 c 0,0.58 -0.19,1.03 -0.58,1.34 -0.39,0.32 -0.92,0.47 -1.59,0.47 -0.37,0 -0.73,-0.07 -1.07,-0.2 -0.34,-0.13 -0.63,-0.33 -0.86,-0.58 l 0.63,-0.6 c 0.16,0.17 0.35,0.3 0.58,0.39 0.23,0.09 0.47,0.13 0.72,0.13 0.4,0 0.71,-0.09 0.94,-0.26 0.23,-0.18 0.34,-0.41 0.34,-0.69 0,-0.29 -0.11,-0.54 -0.32,-0.73 -0.21,-0.19 -0.55,-0.29 -1.01,-0.29 h -0.73 v -0.8 h 0.73 c 0.41,0 0.71,-0.09 0.91,-0.27 0.2,-0.18 0.3,-0.41 0.3,-0.69 0,-0.26 -0.1,-0.48 -0.29,-0.65 -0.19,-0.17 -0.49,-0.25 -0.89,-0.25 -0.26,0 -0.49,0.04 -0.7,0.13 -0.21,0.09 -0.4,0.21 -0.56,0.36 l -0.62,-0.64 c 0.24,-0.23 0.52,-0.4 0.84,-0.52 0.32,-0.12 0.67,-0.18 1.04,-0.18 0.34,0 0.64,0.04 0.9,0.13 0.26,0.09 0.48,0.21 0.65,0.37 0.18,0.16 0.31,0.33 0.39,0.54 0.09,0.2 0.13,0.42 0.13,0.65 0,0.3 -0.07,0.56 -0.21,0.79 -0.14,0.23 -0.35,0.42 -0.61,0.56 v 0.02 c 0.32,0.16 0.56,0.36 0.71,0.62 0.15,0.27 0.23,0.55 0.23,0.85 z"
   id="path7265" />
					<path
   d="m 197.75,144.35 c 0.35,0 0.66,0.07 0.95,0.21 0.28,0.14 0.52,0.34 0.72,0.6 0.2,0.27 0.35,0.6 0.46,0.99 0.11,0.39 0.16,0.85 0.16,1.36 0,0.51 -0.05,0.97 -0.16,1.36 -0.11,0.39 -0.26,0.73 -0.46,0.99 -0.2,0.27 -0.44,0.47 -0.72,0.61 -0.28,0.14 -0.6,0.21 -0.95,0.21 -0.35,0 -0.67,-0.07 -0.95,-0.21 -0.28,-0.14 -0.52,-0.34 -0.72,-0.61 -0.2,-0.27 -0.35,-0.6 -0.46,-0.99 -0.11,-0.39 -0.16,-0.85 -0.16,-1.36 0,-0.51 0.05,-0.97 0.16,-1.36 0.11,-0.39 0.26,-0.72 0.46,-0.99 0.2,-0.27 0.44,-0.47 0.72,-0.6 0.28,-0.14 0.6,-0.21 0.95,-0.21 z m 0,5.48 c 0.45,0 0.8,-0.19 1.03,-0.57 0.24,-0.38 0.35,-0.96 0.35,-1.74 0,-0.78 -0.12,-1.36 -0.35,-1.74 -0.24,-0.38 -0.58,-0.57 -1.03,-0.57 -0.45,0 -0.8,0.19 -1.03,0.57 -0.24,0.38 -0.35,0.96 -0.35,1.74 0,0.78 0.12,1.36 0.35,1.74 0.23,0.38 0.58,0.57 1.03,0.57 z"
   id="path7267" />
					<path
   d="m 203.03,144.35 c 0.35,0 0.66,0.07 0.95,0.21 0.28,0.14 0.52,0.34 0.72,0.6 0.2,0.27 0.35,0.6 0.46,0.99 0.11,0.39 0.16,0.85 0.16,1.36 0,0.51 -0.05,0.97 -0.16,1.36 -0.11,0.39 -0.26,0.73 -0.46,0.99 -0.2,0.27 -0.44,0.47 -0.72,0.61 -0.28,0.14 -0.6,0.21 -0.95,0.21 -0.35,0 -0.67,-0.07 -0.95,-0.21 -0.28,-0.14 -0.52,-0.34 -0.72,-0.61 -0.2,-0.27 -0.35,-0.6 -0.46,-0.99 -0.11,-0.39 -0.16,-0.85 -0.16,-1.36 0,-0.51 0.05,-0.97 0.16,-1.36 0.11,-0.39 0.26,-0.72 0.46,-0.99 0.2,-0.27 0.44,-0.47 0.72,-0.6 0.28,-0.14 0.6,-0.21 0.95,-0.21 z m 0,5.48 c 0.45,0 0.8,-0.19 1.03,-0.57 0.24,-0.38 0.35,-0.96 0.35,-1.74 0,-0.78 -0.12,-1.36 -0.35,-1.74 -0.24,-0.38 -0.58,-0.57 -1.03,-0.57 -0.45,0 -0.8,0.19 -1.03,0.57 -0.24,0.38 -0.35,0.96 -0.35,1.74 0,0.78 0.12,1.36 0.35,1.74 0.23,0.38 0.58,0.57 1.03,0.57 z"
   id="path7269" />
					<path
   d="m 210.28,149.75 v 0.81 h -3.91 v -0.74 l 2.02,-2.14 c 0.15,-0.16 0.29,-0.31 0.41,-0.44 0.12,-0.13 0.22,-0.26 0.3,-0.38 0.08,-0.12 0.15,-0.24 0.19,-0.35 0.04,-0.12 0.07,-0.24 0.07,-0.36 0,-0.27 -0.08,-0.49 -0.25,-0.68 -0.17,-0.19 -0.42,-0.27 -0.75,-0.27 -0.33,0 -0.59,0.1 -0.76,0.31 -0.17,0.21 -0.28,0.48 -0.33,0.81 l -0.92,-0.26 c 0.08,-0.51 0.3,-0.93 0.64,-1.24 0.34,-0.31 0.8,-0.47 1.37,-0.47 0.32,0 0.59,0.05 0.83,0.15 0.24,0.1 0.43,0.23 0.59,0.39 0.16,0.16 0.28,0.36 0.36,0.58 0.08,0.22 0.12,0.45 0.12,0.7 0,0.19 -0.03,0.36 -0.08,0.53 -0.06,0.17 -0.14,0.33 -0.24,0.49 -0.1,0.16 -0.22,0.32 -0.36,0.48 -0.14,0.16 -0.29,0.33 -0.45,0.49 l -1.49,1.58 v 0.02 h 2.64 z"
   id="path7271" />
					<path
   d="m 215.61,149.19 h -2.89 l -0.59,1.38 h -1.03 l 2.66,-6.11 h 0.82 l 2.67,6.11 h -1.04 z m -1.98,-2.2 c -0.18,0.44 -0.36,0.88 -0.54,1.32 h 2.15 c -0.18,-0.44 -0.37,-0.88 -0.54,-1.32 -0.18,-0.44 -0.35,-0.88 -0.52,-1.32 h -0.02 c -0.18,0.44 -0.35,0.88 -0.53,1.32 z"
   id="path7273" />
				</g>
			</g><rect
       x="112.665"
       y="107.68262"
       class="st24"
       width="23.463028"
       height="4.4900227"
       id="rect12164"
       style="fill:#e6e6e6;fill-opacity:1;stroke-width:0.109789" /><image
       width="15.708097"
       height="1.978892"
       preserveAspectRatio="none"
       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPgAAAAgCAYAAAA/vLVJAAAABHNCSVQICAgIfAhkiAAAAIBJREFU eJzt07ENwCAAwLDS/49kYuIHekWFFNkXZMnYa54HSHpvBwD/MTiEGRzCDA5hBocwg0OYwSHM4BBm cAgzOIQZHMIMDmEGhzCDQ5jBIczgEGZwCDM4hBkcwgwOYQaHMINDmMEhzOAQZnAIMziEGRzCDA5h Bocwg0OYwSHsA0SyA/G08krjAAAAAElFTkSuQmCC "
       id="image3053"
       x="113.03014"
       y="104.78159" /><text
       xml:space="preserve"
       style="font-size:1.06815px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.291399"
       x="112.10025"
       y="106.40897"
       id="text294-2-02-5-0"
       transform="scale(1.0022779,0.99772723)"><tspan
         sodipodi:role="line"
         id="txt-device-7"
         x="112.10025"
         y="106.40897"
         style="font-size:1.06815px;fill:#232626;fill-opacity:1;stroke-width:0.291399">XXXX IMP/M3 PULSE RC4 G - TECA</tspan></text><text
       xml:space="preserve"
       style="font-size:2.11667px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.291399"
       x="117.28097"
       y="115.57767"
       id="text294-2-02-5-0-1"
       transform="scale(1.0022779,0.99772727)"
       inkscape:transform-center-x="-0.1984375"
       inkscape:transform-center-y="-0.52916667"><tspan
         sodipodi:role="line"
         id="txt-device-7-8"
         x="117.28097"
         y="115.57767"
         style="font-size:2.11667px;fill:#ffffff;fill-opacity:1;stroke-width:0.291399">m3</tspan></text><text
       xml:space="preserve"
       style="font-size:1.06815px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.291399"
       x="127.43747"
       y="115.49899"
       id="text294-2-02-5-0-6"
       transform="scale(1.0022779,0.99772726)"
       inkscape:transform-center-x="2.619253"
       inkscape:transform-center-y="-5.8602406"><tspan
         sodipodi:role="line"
         id="txt-device-7-4"
         x="127.43747"
         y="115.49899"
         style="font-size:1.06815px;fill:#232626;fill-opacity:1;stroke-width:0.291399">     <tspan
   style="font-size:1.41111px"
   id="tspan61759">Qt:x m3/h</tspan></tspan></text><path
       id="rect1369"
       style="fill:#000000;fill-opacity:0.90277779;stroke-width:0.608139"
       d="m 111.14603,107.2364 h 26.12557 v 5.37776 h -26.12556 z" /><path
       id="rect1371"
       style="display:inline;fill:#003c89;fill-opacity:1;stroke-width:0.619007"
       d="m 111.527,108.16589 h 2.42397 v 3.51879 H 111.527 Z" /><path
       id="rect1373"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.619007"
       d="m 114.75896,108.16589 h 2.42397 v 3.51879 h -2.42397 z" /><path
       id="rect1375"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.619007"
       d="m 118.04865,108.16589 h 2.42397 v 3.51879 h -2.42397 z" /><path
       id="rect1377"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.619007"
       d="m 121.33829,108.16589 h 2.42396 v 3.51879 h -2.42396 z" /><path
       id="rect1379"
       style="fill:#003c89;fill-opacity:1;stroke-width:0.619007"
       d="m 124.62795,108.16589 h 2.42397 v 3.51879 h -2.42397 z" /><path
       id="rect1385"
       style="fill:#cf2c03;fill-opacity:1;stroke-width:0.619007"
       d="m 127.91764,108.16589 h 2.42395 v 3.51879 h -2.42395 z" /><path
       id="rect1391"
       style="fill:#cf2c03;fill-opacity:1;stroke-width:0.619007"
       d="m 131.20731,108.16589 h 2.42395 v 3.51879 h -2.42395 z" /><path
       id="rect1397"
       style="fill:#cf2c03;fill-opacity:1;stroke-width:0.619007"
       d="m 134.49699,108.16589 h 2.42396 v 3.51879 h -2.42396 z" /><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.16832px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="136.17665"
       y="110.00413"
       id="L1_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L1"
         x="136.17665"
         y="110.00413"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.16832px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="132.87682"
       y="109.95705"
       id="L2_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L2"
         x="132.87682"
         y="109.95705"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.16832px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="129.52908"
       y="109.95705"
       id="L3_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L3"
         x="129.52908"
         y="109.95705"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.16832px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="126.18935"
       y="109.95705"
       id="L4_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L4"
         x="126.18935"
         y="109.95705"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.16832px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="122.86462"
       y="109.95705"
       id="L5_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L5"
         x="122.86462"
         y="109.95705"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:3.78937px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="119.52068"
       y="109.95705"
       id="L6_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L6"
         x="119.52068"
         y="109.95705"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:4.16832px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="116.19814"
       y="109.95705"
       id="L7_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L7"
         x="116.19814"
         y="109.95705"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:3.78937px;line-height:1.25;font-family:sans-serif;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.284203"
       x="112.92197"
       y="109.95705"
       id="L8_"
       transform="scale(0.98677384,1.0134034)"><tspan
         sodipodi:role="line"
         id="L8"
         x="112.92197"
         y="109.95705"
         style="font-size:4.16832px;fill:#ffffff;stroke-width:0.284203">0</tspan></text><g
       id="g14743"
       style="display:inline"
       transform="matrix(0.99670635,0,0,0.98678727,0.67426311,2.8699625)"><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.82067;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 114.21313,151.41877 h 4.69748"
         id="path4874" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.82067;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 129.40363,151.37496 h 4.6975"
         id="path4874-8" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 118.96977,147.77081 0.0255,7.10768"
         id="path6336" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 129.30072,147.87699 0.0256,7.10765"
         id="path6336-1" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.722807;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 124.12535,145.61471 0.033,4.93144"
         id="path6336-1-5" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 119.05069,154.8646 10.25382,-6.96013"
         id="path6414" /><path
         style="fill:#003c89;stroke:#1f1a17;stroke-width:0.713082;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 118.97911,147.75637 9.90067,6.91658 0.40684,0.28421"
         id="path6416"
         sodipodi:nodetypes="ccc" /><ellipse
         style="display:inline;fill:#ffffff;fill-opacity:1;stroke:#1f1a17;stroke-width:1.34945;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         id="path6390"
         cx="124.15238"
         cy="151.39345"
         rx="2.0988505"
         ry="2.1219249" /><ellipse
         style="display:inline;fill:#ffffff;fill-opacity:1;stroke:#1f1a17;stroke-width:0.943772;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         id="path6390-9"
         cx="124.12542"
         cy="144.27608"
         rx="1.1400089"
         ry="1.1817735" /><text
         xml:space="preserve"
         style="font-size:1.89555px;line-height:1.25;font-family:sans-serif;fill:#232626;fill-opacity:1;stroke-width:0.118472"
         x="124.05163"
         y="143.9845"
         id="text294-2-2-4"
         transform="scale(0.99460361,1.0054257)"><tspan
           sodipodi:role="line"
           id="tspan292-1-1-9"
           x="124.05163"
           y="143.9845"
           style="font-size:1.2637px;fill:#232626;fill-opacity:1;stroke-width:0.118472"><tspan
   style="font-size:1.63659px;stroke-width:0.118472"
   id="tspan14711">M</tspan> </tspan></text></g><g
       id="KM1"
       style="display:inline"
       transform="matrix(0.29490548,0,0,0.28286378,88.096499,116.02379)"><rect
         style="display:inline;fill:#ffffff;fill-opacity:1;stroke:#ffffff;stroke-width:5.81337;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers"
         id="rect18752"
         width="18.092106"
         height="3.3407004"
         x="114.26294"
         y="126.34528" /><path
         style="display:inline;fill:#003c89;stroke:#1f1a17;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 115.20241,128.02877 h 15.85312"
         id="path4874-8-0" /><path
         style="display:inline;fill:#003c89;stroke:#1f1a17;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 127.80101,126.35616 0.60079,0.28969 2.72202,1.31249"
         id="path4874-8-0-9"
         sodipodi:nodetypes="ccc" /><path
         style="display:inline;fill:#003c89;stroke:#1f1a17;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;paint-order:stroke fill markers"
         d="m 131.12666,128.06261 -0.60079,0.28969 -2.72202,1.31249"
         id="path4874-8-0-9-1"
         sodipodi:nodetypes="ccc"
         inkscape:transform-center-x="3.2742112"
         inkscape:transform-center-y="0.23151038" /></g></g><style
     type="text/css"
     id="style11901">
	.st0{fill:#333435;}
	.st1{font-family:'MyriadPro-Regular';}
	.st2{font-size:15.1008px;}
	.st3{font-size:18px;}
	.st4{font-size:21px;}
	.st5{font-size:13.4951px;}
	.st6{font-size:12.1191px;}
	.st7{font-size:16.5828px;}
	.st8{font-size:9.9589px;}
	.st9{font-family:'Calculator';}
	.st10{font-size:45.1149px;}
	.st11{letter-spacing:4;}
	.st12{font-size:23px;}
	.st13{fill:#BABCBF;}
	.st14{fill:#A2A4A8;}
	.st15{fill:#202024;}
	.st16{fill:#D6D7D9;}
	.st17{fill:#BCBEBF;}
	.st18{fill:#4E4E53;}
	.st19{fill:#A84B49;}
	.st20{fill:#C1C1BD;}
	.st21{fill:#101012;}
	.st22{fill:#F7F7F7;}
	.st23{fill:#8B8D8F;}
	.st24{fill:#C7DA9D;}
	.st25{fill:#6D454A;}
	.st26{fill:#A35559;}
	.st27{fill:#282834;}
</style></svg>


        `,
    };
  },
  methods: {
    async showSvg(msg) {
      this.open = true;
      const { check, bateria, detalle, consumo, campana, statusControl } =
        toSvg.toParseSvg(msg);
      consumo.forEach((element) => {
        document.getElementById(element.elementId).textContent =
          element.textContent;
      });
      detalle.forEach((element) => {
        document.getElementById(element.elementId).textContent =
          element.textContent;
      });
      bateria.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      campana.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      check.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      statusControl.forEach((element) => {
        document.getElementById(element.elementId).style.display =
          element.attributeValue;
      });
      console.log(msg.porcentajeBateria);
      const levels = ["bat_L1", "bat_L2", "bat_L3", "bat_L4", "bat_L5"];

      for (let i = 0; i < levels.length; i++) {
        if (
          msg.porcentajeBateria > i * 20 &&
          msg.porcentajeBateria <= (i + 1) * 20
        ) {
          for (let j = i; j < levels.length; j++) {
            document.getElementById(levels[j]).style.display = "none";
          }
          break;
        }
      }
    },
  },
};
</script>

<style></style>
